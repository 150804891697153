import React, { useContext } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import LayoutComponent from "../components/Layout/Layout.js";
import { BrowserRouter } from "react-router-dom";
import ErrorPage from "../pages/error/ErrorPage.js"
import Login from "../pages/login/Login.js"
import "../styles/app.scss";
import { ToastContainer } from "react-toastify";
import { AnalyticsComponent } from "../actions/firebase";
import { signOut } from "../actions/firebase";
import UserProvider, { UserContext } from "../actions/UserProvider.js";
import { isAuthenticated } from "../actions/firebase";
import PleaseWait from "../pages/login/PleaseWait.js"

const PrivateRoute = ({ dispatch, component, ...rest }) => {
  // const user = useContext(UserContext)
  const _isAuthenticated = isAuthenticated()
  console.log('_isAuthenticated ' + _isAuthenticated)
  return (<Route exact {...rest} render={props => (React.createElement(component, props))} />)
  if (_isAuthenticated === false) {
    // signOut() // ensure logged out, NO!
    return (<Redirect to="/login" />)
  } else {
    return (<Route exact {...rest} render={props => (React.createElement(component, props))} />)
  }
}

class App extends React.PureComponent {
  render() {
    return (
      <div>
        <ToastContainer />
        <BrowserRouter>
          {/* <UserProvider> */}
            <Switch>
              <Route path="/" exact render={() => <Redirect to="/inside/feed" />} />
              <PrivateRoute path="/inside" dispatch={this.props.dispatch} component={LayoutComponent} />
              <Route path="/login" exact component={Login} />
              <Route path="/error" exact component={ErrorPage} />
              <Route path="/wait" exact component={PleaseWait} />
              <Route component={ErrorPage} />
              <Route path='*' exact={true} render={() => <Redirect to="/error" />} />
            </Switch>
            <AnalyticsComponent />
          {/* </UserProvider> */}
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
