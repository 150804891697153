import React from "react";
import {
  Col,
  Row,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import { AiTwotoneFire } from 'react-icons/ai'
import { MdComment } from 'react-icons/md'
import s from "./Feed.module.scss";
import Skeleton from 'react-loading-skeleton';
import { firebaseFirestore } from '../../actions/firebase'
import UserProvider, { UserContext } from "../../actions/UserProvider.js";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
} from 'react-router-dom'
import { timeDifference } from '../../actions/helpers'

export default class Feed extends React.Component {
  // static contextType = UserContext

  constructor(props) {
    super(props);

    // this.toggleOne = this.toggleOne.bind(this);

    this.state = {
      snapshotContent: null,
      arrayContent: null,
      showPlaceholderImage: true,
    }
  }

  async componentDidMount() {
    console.log('Dashboard componentDidMount')
    console.log(window.location.href)
    // await sleep(2000) // remove this on production

    // Step 1: Get my user info
    // const uid = localStorage.getItem('uid')
    // if (!uid) { return }
    firebaseFirestore.collection('landing-page')
      .get()
      .then(snapshot => {
        this.setState({
          arrayContent: snapshot.docs
        }, () => {
          console.log(this.state.arrayContent)
        })
      })
  }

  retrieveContent(subscribeTo) {
    // const meSubscribeTo = ['p4fXPZNJI9ZKfN4flcdT', 'another-creator']
    this.listenerContent = firebaseFirestore
      .collection('content')
      .where('creatorId', 'in', subscribeTo)
      .onSnapshot(snapshot => {
        // const _arrayContent = snapshot.docs.map(_doc => _doc.data())
        this.setState({
          snapshotContent: snapshot,
          arrayContent: snapshot.docs
        })
      })
  }

  componentWillUnmount() {
    console.log('Dashboard componentWillUnmount')
    if (this.listenerContent) {
      this.listenerContent() // Unsubscribe
    }
  }

  render() {
    return (
      <div className='justify-content-center d-flex'>
        <div style={{ maxWidth: 720, width: '100%' }}>
          <div className='headline-1 mb-3'>NEWS FEED</div>
          {/* <div className="headline-2 d-flex justify-content-center">NEWS FEED</div> <br /> */}
          {this.state.arrayContent ? this.state.arrayContent.map(content =>
            <DashboardCell
              content={content}
              key={content.id}
              showPlaceholderImage={this.state.showPlaceholderImage}
              hidePlaceholderImage={() => this.setState({ showPlaceholderImage: false })} />) : <DashboardSkeletonGroup />}
        </div>
      </div>
    )
  }
}

function DashboardCell(props) {
  return (
    <Link to={`/inside/feed/${props.content.id}`} key={props.content.id}>
      <Row>
        <Col className="pr-grid-col mt-2 mb-4">
          <Widget className="p-3">
            {props.showPlaceholderImage === true ? <img className="img-fluid rounded" src="/mentoro-grayscale-placeholder.jpg" /> : <div />}
            <img className="img-fluid rounded w-100" src={props.content.data().posterUrl} onLoad={props.hidePlaceholderImage} />
            <div className="row container-fluid mt-2">
              <div className="col">
                <p className="headline-2">{props.content.data().title}</p>
                <p className="body-3">{props.content.data().creatorName}</p>
              </div>
              <div className="col-sm col-sm-auto align-items-center d-flex">
                <AiTwotoneFire size={18} />
                <span>&nbsp;{props.content.data().likeCount}</span>
              </div>
              <div className="col-sm col-sm-auto align-items-center d-flex">
                <MdComment size={18} />
                <span>&nbsp;{props.content.data().commentCount}</span>
              </div>
            </div>
          </Widget>
        </Col>
      </Row>
    </Link>)
}

function DashboardSkeletonGroup() {
  var rows = [];
  for (var i = 0; i < 3; i++) {
    rows.push(DashboardSkeletonCell(i));
  }
  return (<div>{rows}</div>);
}

function DashboardSkeletonCell(index) {
  return (
    <Row key={index}>
      <Col className="pr-grid-col mt-2 mb-4">
        <Widget className="p-3">
          <Skeleton className='w-100' height={180} />
          <Skeleton className='mt-2' />
          <Skeleton />
        </Widget>
      </Col>
    </Row>
  )
}
