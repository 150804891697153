import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaBeer, FaRegEnvelopeOpen, FaSearch, FaUserCircle } from 'react-icons/fa';

import { logoutUser } from "../../actions/auth.js";
import { closeSidebar, openSidebar } from "../../actions/navigation.js";
import MenuIcon from "../Icons/HeaderIcons/MenuIcon.js"
import SearchIcon from "../Icons/HeaderIcons/SearchIcon.js";

import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon.js";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon.js";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon.js";

import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg"
import userImg from "../../assets/Victor2020.jpg";

import s from "./Header.module.scss";
import "animate.css";
import { signOut } from '../../actions/firebase'

class Header extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);

    this.state = {
      menuOpen: false,
      notificationsOpen: false,
      searchFocused: false,
    };
  }

  componentDidMount() {
    console.log('Header componentDidMount')
  }

  doLogout(e) {
    e.preventDefault()
    // this.props.dispatch(logoutUser());
    signOut()
  }

  toggleSidebar() {
    if (this.props.sidebarOpened) {
      this.props.dispatch(closeSidebar());
    } else {
      const paths = this.props.location.pathname.split('/');
      paths.pop();
      this.props.dispatch(openSidebar());
    }
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  toggleNotifications() {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen,
    });
  }

  render() {

    return (
      <Navbar className={`${s.root} d-print-none ${s.dynamicHeader}`}>
        <NavLink
          onClick={this.toggleSidebar}
          className={`d-md-none mr-3 ${s.navItem}`}
          href="#">
          <MenuIcon className={s.menuIcon} />
        </NavLink>

        <div className='d-md-none' style={{ width: 300 }}>
          <a href='/'>
            <img
              className='w-50 rounded' style={{ backgroundColor: '#58c3c1' }}
              src='https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/general%2Fmentoro-logo-top-mid-transparent.png?alt=media&token=00ce35ab-5d3c-43ca-bfff-94b104e92d7c' />
          </a>
        </div>

        <Nav className="ml-auto">

          <Dropdown isOpen={this.state.notificationsOpen} toggle={this.toggleNotifications} nav id="basic-nav-dropdown" className="ml-3">
            <DropdownToggle nav className="navbar-dropdown-toggle">
              <span className={`${s.avatar} rounded-circle float-left mr-2`}>
                <FaUserCircle size={28} />
              </span>
              <span className="d-none d-sm-none d-md-block mr-2 body-1">Welcome, Guest!</span>
            </DropdownToggle>

            {/* <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
              <DropdownItem className={s.dropdownProfileItem}><ProfileIcon /><span>My Profile</span></DropdownItem>
              <DropdownItem className={s.dropdownProfileItem}><TasksIcon /><span>Settings</span></DropdownItem>
              <NavItem>
                <NavLink onClick={this.doLogout} href="#">
                  <button className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit"><img src={logoutIcon} alt="Logout" /><span className="ml-1">Logout</span></button>
                </NavLink>
              </NavItem>
            </DropdownMenu> */}
          </Dropdown>
        </Nav>
      </Navbar>
    )
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

