import { useEffect, createContext, useContext, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { firebaseAuth, firebaseFirestore, firebaseTimestamp, isAuthenticated, firebaseStorage } from "./firebase";
import { sleep } from './helpers';

// We're still not yet able to utilise context properly. useContext() still undefined.
export const UserContext = createContext({ user: 'hello' });
class UserProvider extends Component {
   state = {
      userAuth: null,
   }
   componentDidMount = () => {
      console.log('UserProvider componentDidMount')
      // https://firebase.google.com/docs/auth/web/manage-users
      this.listenerAuth = firebaseAuth.onAuthStateChanged(userAuth => {
         if (userAuth) {
            // Happen once on web open / refresh
            console.log('user login success, isAuthenticated:' + isAuthenticated())
            localStorage.setItem('authenticated', true) // must come after isAuthenticated()
            localStorage.setItem('uid', userAuth.uid)
            // Save to state
            this.setState({ userAuth: userAuth })

            // Check where we came from
            if (this.props.history.location.pathname == '/login') {
               // If user comes from login page, need to go to wait page while fetching user object
               this.props.history.push('/wait')
               this.retrieveUserObject(userAuth.uid)
            }

         } else {
            console.log('user login fail')
            localStorage.removeItem('authenticated')
            localStorage.removeItem('uid')
            localStorage.removeItem('loginInfo')
            this.props.history.push('/login')
         }
      })
   }
   // Step 2. Retrieve one time only, to check subscribeTo array. isFromLogin always true
   retrieveUserObject = (uid) => {
      const refUser = firebaseFirestore.collection('user').doc(uid)
      refUser.get()
         .then(async doc => {
            if (doc.exists === false) {
               // Redo until exist, recursive. This only happen on first time login, CF has not created user yet
               await sleep(500)
               this.retrieveUserObject(uid)
               return
            }
            // Aftermath
            if (!doc.data().subscribeTo) { // Instead of length == 0, check undefined
               // Comes from login page && new user
               this.props.history.push('/inside/find-creators') // Go to creator search now!
            } else {
               // Returning user re-logging in
               this.props.history.push('/inside/feed')
            }

            // Side work: additional user info
            const loginInfoString = localStorage.getItem('loginInfo')
            const loginInfo = JSON.parse(loginInfoString)
            fetch('https://ipapi.co/json/')
               .then(response => response.json())
               .then(async data => { // data is JSON
                  var json = {
                     lastSignedIn: firebaseTimestamp,
                     location: data,
                     country: data.country_name,
                     state: data.region,
                     city: data.city,
                     language: data.languages,
                     loginInfo: loginInfo
                  }
                  // Only for new user
                  if (loginInfo.isNewUser === true) {
                     // Set basic info
                     json.nameFirst = loginInfo.nameFirst
                     json.nameLast = loginInfo.nameLast
                     json.gender = loginInfo.gender
                     // Set profile picture first time
                     switch (loginInfo.provider) {
                        case 'facebook':
                           // Download image, store to bucket and firestore
                           const downloadUrl = loginInfo.photoUrl + '?width=120&height=120'
                           const blob = await fetch(downloadUrl).then(r => r.blob())
                           const uid = loginInfo.additionalUserInfo.profile.id
                           const fileRefOriginal = firebaseStorage.child("upload_image/" + "profile_" + uid)
                           const snapshot = await fileRefOriginal.put(blob) // upload
                           const photoUrl = await this.getDownloadURL(uid) // get compressed url
                           json.photoUrl = photoUrl
                           console.log('photoUrl ' + photoUrl)
                           break
                        case 'google':
                           json.photoUrl = loginInfo.photoUrl
                           break
                        default: break
                     }
                  }
                  refUser.update(json)
               })
         })
   }
   getDownloadURL = async (uid) => {
      const fileRefCompressed = firebaseStorage.child("upload_image/" + "profile_" + uid + "_1024x1024")
      try {
         const photoUrl = await fileRefCompressed.getDownloadURL()
         return photoUrl
      } catch (error) {
         console.log('sleeping...')
         sleep(500)
         return this.getDownloadURL(uid)
      }
   }

   componentWillUnmount = () => {
      console.log('UserProvider componentWillUnmount')
      // Unsubscribe
      this.listenerAuth()
   }
   render() {
      return (
         <UserContext.Provider value={this.state} >
            {this.props.children}
         </UserContext.Provider >
      );
   }
}
export default withRouter(UserProvider);
// export default UserProvider;