import React from "react";
import s from "./Footer.module.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className={this.props.login === true ? s.footerLogin : s.footer}>
        <span className={s.footerLabel}>&copy; 2021 Small Builds Pty Ltd. Made with 💖</span>
      </div>
    )
  }
}

export default Footer;
