import React from 'react';

class TablesIcon extends React.Component {

  render() {
    return (
      <svg className={this.props.className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.857 1.33334H13.0347C13.9357 1.33334 14.6666 2.07061 14.6666 2.98013V5.17608C14.6666 6.08491 13.9357 6.82287 13.0347 6.82287H10.857C9.95528 6.82287 9.22437 6.08491 9.22437 5.17608V2.98013C9.22437 2.07061 9.95528 1.33334 10.857 1.33334Z" stroke="#16365F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.96587 1.33334H5.14291C6.04461 1.33334 6.77553 2.07061 6.77553 2.98013V5.17608C6.77553 6.08491 6.04461 6.82287 5.14291 6.82287H2.96587C2.06417 6.82287 1.33325 6.08491 1.33325 5.17608V2.98013C1.33325 2.07061 2.06417 1.33334 2.96587 1.33334Z" stroke="#16365F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.96587 9.17715H5.14291C6.04461 9.17715 6.77553 9.91442 6.77553 10.8246V13.0199C6.77553 13.9294 6.04461 14.6667 5.14291 14.6667H2.96587C2.06417 14.6667 1.33325 13.9294 1.33325 13.0199V10.8246C1.33325 9.91442 2.06417 9.17715 2.96587 9.17715Z" stroke="#16365F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.857 9.17715H13.0347C13.9357 9.17715 14.6666 9.91442 14.6666 10.8246V13.0199C14.6666 13.9294 13.9357 14.6667 13.0347 14.6667H10.857C9.95528 14.6667 9.22437 13.9294 9.22437 13.0199V10.8246C9.22437 9.91442 9.95528 9.17715 10.857 9.17715Z" stroke="#16365F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    );
  }
}

export default TablesIcon;
