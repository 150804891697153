import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";
import { loginUser } from "../../actions/auth.js";
import loginImage from "../../assets/loginImage.svg";
import { FaBeer } from 'react-icons/fa';
import { GoogleLoginButton, FacebookLoginButton, LinkedInLoginButton } from "react-social-login-buttons";
import { loginWithFacebook, loginWithGoogle } from "../../actions/firebase";


class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: 'admin@flatlogic.com',
      password: 'password',
    };

    this.doLogin = this.doLogin.bind(this);
    this.doLoginGoogle = this.doLoginGoogle.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  doLogin(e) {
    e.preventDefault();
    this.props.dispatch(loginUser({ email: this.state.email, password: this.state.password }));
  }

  doLoginFacebook() {
    loginWithFacebook()
  }

  doLoginGoogle() {
    loginWithGoogle()
  }

  changeEmail(event) {
    this.setState({ email: event.target.value });
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/inside' } };

    if (this.context.UserContext != null) {
      return (<Redirect to={from} />)
    }

    return (
      <div className="auth-page">
        <Container className="col-12" style={{ backgroundColor: 'white' }}>
          <Row className="d-flex align-items-center">
            <Col xs={0} lg={6} className="left-column" style={{ backgroundColor: '#58c3c1' }}>
              <div className='p-4 mr-2'>
                <img src='https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/general%2Fmentoro-transparent-big-subtitle-800.png?alt=media&token=e37aebeb-3e93-402f-8d95-abadec3b0353' className='w-100' />
              </div>
              <Footer login={true} />
            </Col>

            <Col xs={12} lg={6} className="right-column" >
              <Widget className="widget-auth widget-p-lg" >
                <div className="d-flex align-items-center justify-content-between py-3">
                  <p className="auth-header mb-0">Login to Mentoro.app</p>
                </div>
                <div className="auth-info my-2">
                  <p>This Login page is still half baked. I am not focusing on login UI at the moment. Please go ahead and press DEMO LOGIN button below.</p>
                </div>

                {/* <form onSubmit={this.doLogin}>
                  <FormGroup className="my-3">
                    <FormText>Email</FormText>
                    <Input id="email" className="input-transparent pl-3" value={this.state.email} onChange={this.changeEmail} type="email" required name="email" placeholder="Email" />
                  </FormGroup>
                  <FormGroup className="my-3">
                    <div className="d-flex justify-content-between">
                      <FormText>Password</FormText>
                      <Link to="/error">Forgot password?</Link>
                    </div>
                    <Input id="password" className="input-transparent pl-3" value={this.state.password} onChange={this.changePassword} type="password" required name="password" placeholder="Password" />
                  </FormGroup>
                  <div className="bg-widget d-flex justify-content-center">
                    <Button className="rounded-pill my-3" type="submit" color="complement">DEMO LOGIN</Button>
                  </div>
                  <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                  <Link to="/register">Don’t have an account? Sign Up here</Link>
                </form> */}

                <div className="d-flex justify-content-center my-3">
                  {/* <p className="social-label mb-0">Login with</p> */}
                  <div className="socials">

                    <FacebookLoginButton onClick={this.doLoginFacebook} size={38} iconSize={22}>
                      <span className="h6 small">Login with Facebook</span>
                    </FacebookLoginButton>
                    <div style={{ height: 4 }} />
                    <GoogleLoginButton onClick={this.doLoginGoogle} size={38} iconSize={22}>
                      <span className="h6 small">Login with Google</span>
                    </GoogleLoginButton>
                    <div style={{ height: 4 }} />

                    {/* <LinkedInLoginButton size={38} iconSize={22}>
                      <span className="h6 small">Login with LinkedIn</span>
                    </LinkedInLoginButton> */}

                    {/* <button><img src="./img/google.png" alt="my image" onClick={this.doLoginGoogle} /></button>
                      <button onClick={this.doLoginGoogle}>Google Sign In</button> */}
                  </div>
                </div>

              </Widget>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

// export default withRouter(connect(mapStateToProps)(Login));
export default Login
