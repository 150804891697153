import React from "react"
import {
   Container,
   Row,
   Col,
} from "reactstrap"
import Widget from "../../components/Widget/Widget.js"
import Footer from "../../components/Footer/Footer.js"
import YoutubeVideo from "./YoutubeVideo.js"
import {
   firebaseFirestore,
} from "../../actions/firebase"

export default class JoinSuccess extends React.Component {
   constructor(props) {
      super(props)
      this.doSubmit = this.doSubmit.bind(this)

      this.state = {
         tallyNumber: 0
      }
   }

   componentDidMount() {
      // Concurrent #1: 
      // const statsRef = firebaseFirestore.collection('stats').doc('stats')
      // statsRef.get().then(doc => {
      //    const totalUser = doc.data().totalUser
      //    this.setState({
      //       tallyNumber: totalUser
      //    })
      // })
   }

   doSubmit(e) {
      e.preventDefault();
   }

   render() {
      return (
         <div>

            <YoutubeVideo />

            <Container className="col-12 d-flex justify-content-center mt-4">
               <Row className="d-flex align-items-center">
                  <Col>
                     {/* <Widget className="widget-auth widget-p-lg"> */}
                        {/* <video
                           // poster="https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/explanation-video%2Fposter.jpg?alt=media&token=625b4579-c654-4eee-9905-045abb56c03b"
                           id="vid" width="100%" preload="auto" onContextMenu={e => e.preventDefault()}
                           controls disablePictureInPicture controlsList="nodownload">
                           <source
                              src="https://youtu.be/uWJkQSwCHyQ"
                              type="video/mp4" />
                        </video> */}
                     {/* </Widget> */}
                     {/* <Tally tallyNumber={this.state.tallyNumber}/> */}

                     <Widget className="widget-auth widget-p-lg">
                        <div className="d-flex align-items-center justify-content-center py-3">
                           <p className="auth-header mb-0">Success!</p>
                        </div>
                        <div className="auth-info my-2">
                           <p>Submission successful. We will email you shortly with our exciting progress!</p>
                        </div>

                     </Widget>

                  </Col>
               </Row>

            </Container>
         </div>
      )
   }
}