import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import { logoutUser } from "../../actions/auth.js";
import NotificationsIcon from "../Icons/SidebarIcons/NotificationsIcon.js";
import TablesIcon from "../Icons/SidebarIcons/TablesIcon.js";
import TypographyIcon from "../Icons/SidebarIcons/TypographyIcon.js";
import { FaBeer, FaRegEnvelopeOpen, FaSearch } from 'react-icons/fa';
import { IoPerson } from 'react-icons/io5'
import { AiFillHome } from 'react-icons/ai'
import { MdCloudUpload } from 'react-icons/md'
import { ImFacebook2, ImLinkedin, ImInstagram } from 'react-icons/im'
import { RiPlantFill, RiPlantLine } from 'react-icons/ri'
import { GiCottonFlower } from 'react-icons/gi'


class Sidebar extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    activeItem: "",
  }

  constructor(props) {
    super(props);

    this.doLogout = this.doLogout.bind(this);
  }

  componentDidMount() {
    this.element.addEventListener('transitionend', () => {
      if (this.props.sidebarOpened) {
        this.element.classList.add(s.sidebarOpen);
      }
    }, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.sidebarOpened !== prevProps.sidebarOpened) {
      if (this.props.sidebarOpened) {
        this.element.style.height = `276px`;
      } else {
        this.element.classList.remove(s.sidebarOpen);
        setTimeout(() => {
          this.element.style.height = '0px';
        }, 0);
      }
    }
  }

  doLogout(id) {
    this.props.dispatch(logoutUser());
  }

  render() {
    return (
      <nav className={s.root}
        ref={(nav) => {
          this.element = nav;
        }}>
        <header>
          <a href='/' className={s.logo}>
            <img
              className='w-75'
              src='https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/general%2Fmentoro-transparent-nonsubtitle-400.png?alt=media&token=101fc9e0-a027-4f7b-999a-d4ead762dee0' />
          </a>
        </header>
        <div style={{ height: 16, backgroundColor: '#C7D0D9' }} className='mb-4' />

        <ul className={s.nav}>
          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="News Feed"
            isHeader
            iconName={<AiFillHome className={s.menuIcon} />}
            link="/inside/feed"
            index="feed" />

          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Find Creators"
            isHeader
            iconName={<FaSearch className={s.menuIcon} />}
            link="/inside/find-creators"
            index="find-creators" />

          {/* <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="My User Profile"
            isHeader
            iconName={<IoPerson className={s.menuIcon} />}
            link="/inside/my-user-profile"
            index="my-user-profile" />

          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Subscription List"
            isHeader
            iconName={<IoPerson className={s.menuIcon} />}
            link="/inside/subscription-list"
            index="subscription-list" /> */}

          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Give Feedback"
            isHeader
            iconName={<FaRegEnvelopeOpen className={s.menuIcon} />}
            link="/inside/feedback"
            index="feedback" />

          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Leave a Legacy"
            isHeader
            iconName={<GiCottonFlower className={s.menuIcon} />}
            link="/inside/leave-a-legacy"
            index="leave-a-legacy" />


          {/* <h5 className={[s.navTitle, s.groupTitle].join(" ")}>CREATOR</h5>
          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="My Creator Profile"
            isHeader
            iconName={<IoPerson className={s.menuIcon} />}
            link="/inside/my-creator-profile"
            index="my-creator-profile" />

          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Video Upload"
            isHeader
            iconName={<MdCloudUpload className={s.menuIcon} />}
            link="/inside/video-upload"
            index="video-upload" />

          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="My Subscriber List"
            isHeader
            iconName={<IoPerson className={s.menuIcon} />}
            link="/inside/subscriber-list"
            index="subscriber-list" /> */}


          <h5 className={[s.navTitle, s.groupTitle].join(" ")}>FOLLOW OUR COMMUNITY</h5>

          <div className='ml-2 mt-4'>
            <a href='https://www.linkedin.com/company/mentoroapp' target="_blank">
              <ImLinkedin size={32} style={{ color: '#2867B2' }} className='mr-3' /><span>/mentoroapp</span>
            </a>
          </div>

          <div className='ml-2 mt-2'>
            <a href='https://instagram.com/mentoro.app' target="_blank">
              <ImInstagram size={32} style={{ color: '#C13584' }} className='mr-3' /><span>/mentoro.app</span>
            </a>
          </div>

          <div className='ml-2 mt-2'>
            <a href='https://www.facebook.com/mentoro.global' target="_blank">
              <ImFacebook2 size={32} style={{ color: '#4267B2' }} className='mr-3' /><span>/mentoro.global</span>
            </a>
          </div>

          {/* <h5 className={[s.navTitle, s.groupTitle].join(" ")}>TEMPLATE</h5>
          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Typography"
            isHeader
            iconName={<TypographyIcon />}
            link="/inside/typography"
            index="typography" />
          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Tables"
            isHeader
            iconName={<TablesIcon className={s.menuIcon} />}
            link="/inside/tables"
            index="tables" />
          <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="Notifications"
            isHeader
            iconName={<NotificationsIcon className={s.menuIcon} />}
            link="/inside/notifications"
            index="notifications" /> */}


          {/* <LinksGroup
            onActiveSidebarItemChange={activeItem => this.props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={this.props.activeItem}
            header="UI Elements"
            isHeader
            iconName={<UIElementsIcon className={s.menuIcon}/>}
            link="/template/uielements"
            index="uielements"
            childrenLinks={[
              {
                header: 'Charts', link: '/template/ui-elements/charts',
              },
              {
                header: 'Icons', link: '/template/ui-elements/icons',
              },
              {
                header: 'Google Maps', link: '/template/ui-elements/maps',
              },
            ]}
          /> */}
        </ul>
        {/* <div className="bg-widget d-flex mt-auto ml-1">
          <Button className="rounded-pill my-3 body-2 d-none d-md-block" type="submit" color="secondary-red">Unlock Full Version</Button>
        </div> */}
      </nav>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
