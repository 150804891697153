import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

import Header from "../Header/Header.js";
import Sidebar from "../Sidebar/Sidebar.js";
import Footer from "../Footer/Footer.js";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs.js";

import Feed from "../../pages/home/Feed.js";
import ContentDetails from "../../pages/home/ContentDetails"
import Typography from "../../pages/typography/Typography.js";
import Notifications from "../../pages/notifications/Notifications.js";
import Feedback from "../../pages/feedback/Feedback.js"
import UserProfile from "../../pages/my-profiles/UserProfile.js"
import CreatorProfile from "../../pages/my-profiles/CreatorProfile.js"
import FindCreators from "../../pages/find-creators/FindCreators.js"
import SubscriptionList from "../../pages/subscription/SubscriptionList.js"
import SubscriberList from "../../pages/subscription/SubscriberList.js"
import SubscribeTo from "../../pages/subscription/SubscribeTo.js"
import CreatorDetails from "../../pages/find-creators/CreatorDetails.js"
import VideoUpload from "../../pages/video-upload/VideoUpload.js"
import LeaveALegacy from "../../pages/login/LeaveALegacy.js";
import JoinSuccess from "../../pages/login/JoinSuccess.js";

import Tables from "../../pages/tables/Tables.js";
import Charts from "../../pages/uielements/charts/Charts.js";
import Icons from "../../pages/uielements/icons/IconsPage.js";
import Maps from "../../pages/uielements/maps/google/GoogleMapPage";

import s from "./Layout.module.scss";

class Layout extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarOpened: false,
  };

  render() {
    return (
      <div className={s.root}>
        <div className={s.wrap}>
          <Header />
          <Sidebar />
          <main className={s.content}>
            <Switch>
              <Route path="/" exact render={() => <Redirect to="/inside/feed" />} />
              <Route path="/inside/leave-a-legacy" exact component={LeaveALegacy} />
              <Route path="/inside/join-success" exact component={JoinSuccess} />
              <Route path="/inside/feed" exact component={Feed} />
              <Route path="/inside/feed/:contentId" exact component={ContentDetails} />
              <Route path="/inside/find-creators" exact component={FindCreators} />
              <Route path="/inside/feedback" exact component={Feedback} />
              <Route path="/inside/typography" exact component={Typography} />
              <Route path="/inside/tables" exact component={Tables} />
              <Route path="/inside/notifications" exact component={Notifications} />
              <Route path="/inside/my-user-profile" exact component={UserProfile} />
              <Route path="/inside/my-creator-profile" exact component={CreatorProfile} />
              <Route path="/inside/subscription-list" exact component={SubscriptionList} />
              <Route path="/inside/subscriber-list" exact component={SubscriberList} />
              <Route path="/inside/video-upload" exact component={VideoUpload} />
              <Route path="/inside/subscribe-to/:creatorId" exact component={SubscribeTo} />
              <Route path="/inside/creator-details/:creatorId" exact component={CreatorDetails} />
              <Route path='*' exact render={() => <Redirect to="/error" />} />
            </Switch>
          </main>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
