import dotenv from 'dotenv'
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/analytics"
import "firebase/firestore"
import "firebase/functions"
import "firebase/performance"
import "firebase/storage"
import { useEffect } from 'react'
import { useHistory } from 'react-router'

// Prepare
dotenv.config()
if (!firebase.apps.length) {
   firebase.initializeApp({
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID
   })
} else {
   firebase.app() // if already initialized, use that one
}

// firebase.functions().useEmulator("localhost", 5001) // comment this to use cloud instead of localhost

export const firebaseAuth = firebase.auth()
firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
export const firebaseAnalytics = firebase.analytics()
export const firebaseFirestore = firebase.firestore()
export const firebaseFunctions = firebase.functions()
export const firebasePerformance = firebase.performance()
export const firebaseStorage = firebase.app().storage(process.env.storageBucket).ref()

export const firebaseTimestamp = firebase.firestore.FieldValue.serverTimestamp()
export const firebaseIncrement = firebase.firestore.FieldValue.increment(1)
export const firebaseDecrement = firebase.firestore.FieldValue.increment(-1)

const logCurrentPage = () => {
   console.log("logging screen_view " + window.location.pathname)
   firebaseAnalytics.setCurrentScreen(window.location.pathname)
   firebaseAnalytics.logEvent('screen_view')
};

export const AnalyticsComponent = () => {
   const history = useHistory();
   useEffect(() => {
      logCurrentPage(); // log the first page visit
      history.listen(() => {
         logCurrentPage();
      });
   }, [history]);
   return (<div></div>);
};

export const loginWithFacebook = () => {
   // Prepare
   const provider = new firebase.auth.FacebookAuthProvider()
   provider.addScope('user_gender')
   // provider.addScope('user_birthday') // Need to update FB app to use this

   firebaseAuth
      .signInWithPopup(provider)
      .then(result => {
         console.log("Facebook login success")
         const loginInfoJSON = {
            provider: 'facebook',
            user: result.user,
            additionalUserInfo: result.additionalUserInfo,
            isNewUser: result.additionalUserInfo.isNewUser, // update nameFirst nameLast gender for new user
            nameFirst: result.additionalUserInfo.profile.first_name,
            nameLast: result.additionalUserInfo.profile.last_name,
            gender: result.additionalUserInfo.profile.gender ?? 'other',
            photoUrl: result.user.photoURL,
         }
         localStorage.setItem('loginInfo', JSON.stringify(loginInfoJSON)) // save it for later on UserProvider

         /** @type {firebase.auth.OAuthCredential} */
         // var credential = result.credential;
         // var token = credential.accessToken; // This gives you a Google Access Token. You can use it to access the Google API.
         // var user = result.user; // The signed-in user info.

      }).catch((error) => {
         console.log("Google Auth Error. Tell user");
         // Handle Errors here.
         var errorCode = error.code;
         var errorMessage = error.message;
         // The email of the user's account used.
         var email = error.email;
         // The firebase.auth.AuthCredential type that was used.
         var credential = error.credential;
         // Tell user
         alert("Google Sign In error. Please try again.")
      })
}

export const loginWithGoogle = () => {
   // Prepare
   const provider = new firebase.auth.GoogleAuthProvider();
   // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
   // provider.setCustomParameters({
   //   'login_hint': 'user@example.com'
   // });

   // Execute
   firebaseAuth
      .signInWithPopup(provider)
      .then(result => {
         console.log("Google login success")
         const loginInfoJSON = {
            provider: 'google',
            user: result.user,
            additionalUserInfo: result.additionalUserInfo,
            isNewUser: result.additionalUserInfo.isNewUser, // update nameFirst nameLast gender for new user
            nameFirst: result.additionalUserInfo.profile.given_name,
            nameLast: result.additionalUserInfo.profile.family_name,
            gender: result.additionalUserInfo.profile.gender ?? 'other',
            photoUrl: result.user.photoURL,
         }
         localStorage.setItem('loginInfo', JSON.stringify(loginInfoJSON)) // save it for later on UserProvider

         /** @type {firebase.auth.OAuthCredential} */
         // var credential = result.credential;
         // var token = credential.accessToken; // This gives you a Google Access Token. You can use it to access the Google API.
         // var user = result.user; // The signed-in user info.

      }).catch((error) => {
         console.log("Google Auth Error. Tell user");
         // Handle Errors here.
         var errorCode = error.code;
         var errorMessage = error.message;
         // The email of the user's account used.
         var email = error.email;
         // The firebase.auth.AuthCredential type that was used.
         var credential = error.credential;
         // Tell user
         alert("Google Sign In error. Please try again.")
      })
}

export const signOut = () => {
   firebaseAuth.signOut().then(() => {
      // sign out success
      localStorage.removeItem('authenticated')
      localStorage.removeItem('uid')
   }).catch((error) => {
      // error
   })
}

export const isAuthenticated = () => {
   const authenticated = localStorage.getItem('authenticated')
   if (authenticated) {
      return authenticated
   } else {
      return false
   }
}