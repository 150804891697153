import React, { Component } from 'react'
import {
   Col,
   Row,
} from "reactstrap"
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import {
   firebaseFirestore,
   firebaseTimestamp,
   firebaseIncrement,
   firebaseDecrement
} from '../../actions/firebase'
import Skeleton from 'react-loading-skeleton'
import Widget from "../../components/Widget/Widget.js"
import s from "./ContentDetails.module.scss"
import { AiTwotoneFire } from 'react-icons/ai'
import { MdComment } from 'react-icons/md'
import { FaEye } from 'react-icons/fa'
import { Button, TextField } from '@material-ui/core'
import { timeDifference } from '../../actions/helpers'
import ReactPlayer from "react-player"


export default class ContentDetails extends Component {
   static contextType = UserContext

   constructor(props) {
      super(props)
      // this.retrieveLike = this.retrieveLike.bind(this)
      this.changeLike = this.changeLike.bind(this)
      // this.onCommentTextChange = this.onCommentTextChange.bind(this)
      // this.sendComment = this.sendComment.bind(this)
      this.onPlayVideo = this.onPlayVideo.bind(this)
      this.onLoadedDataVideo = this.onLoadedDataVideo.bind(this)
      this.state = {
         contentId: props.match.params.contentId,
         docContent: null,
         dataContent: null,
         isLiked: false,
         buttonLikeDisabled: false,
         buttonCommentDisabled: false,
         comment: '',
         arrayComment: [],
         userFullName: null,
      }
      // this.refs
      this.refContent = firebaseFirestore.collection('landing-page').doc(props.match.params.contentId)
      // this.refLike = firebaseFirestore.collection('like').doc(localStorage.getItem('uid') + '_' + this.state.contentId)
   }
   componentDidMount() {
      // Step 1: listener content
      this.listenerContent = this.refContent.onSnapshot(doc => {
         // Step 1 final
         this.setState({
            docContent: doc,
            dataContent: doc.data()
         })
      })

      // Step 2: Get my user info, one time
      // const uid = localStorage.getItem('uid')
      // if (!uid) { return }
      // firebaseFirestore.collection('user')
      //    .doc(uid)
      //    .get()
      //    .then(doc => {
      //       // Save to this
      //       this.setState({ userFullName: doc.data().nameDisplay }, () => {
      //          // Step 3 & 4: listener like concurrent. Like one time, Comment concurrently
      //          this.retrieveLike()
      //          this.retrieveComment()
      //       })
      //    })
      //    .catch(error => {
      //       alert(error); return
      //    })
   }
   // retrieveLike() {
   //    this.refLike.get().then(doc => {
   //       // Create new if does not exist
   //       if (doc.exists === false) {
   //          this.refLike.set({
   //             created: firebaseTimestamp,
   //             userId: localStorage.getItem('uid'),
   //             contentId: this.state.contentId,
   //             userFullName: this.state.userFullName,
   //             like: false // default is not liked
   //          })
   //             .then(() => this.retrieveLike()) // recursive after setting
   //             .error(e => alert(e))
   //          return
   //       }
   //       // Step 2 final
   //       this.setState({
   //          isLiked: doc.data().like
   //       })
   //    })
   // }
   changeLike(like) {
      // Disable button
      this.setState({ buttonLikeDisabled: true })
      // Update single doc in collection like
      const batch = firebaseFirestore.batch()
      // batch.update(this.refLike, {
      //    updated: firebaseTimestamp,
      //    like: like
      // })
      batch.update(this.refContent, {
         likeCount: like === true ? firebaseIncrement : firebaseDecrement
      }, { merge: true })
      batch.commit()
         .then(() =>
            this.setState({
               isLiked: like,
               buttonLikeDisabled: false,
            })) // success
   }
   // retrieveComment() {
   //    this.listenerComment = firebaseFirestore.collection('comment')
   //       .where('contentId', '==', this.props.match.params.contentId)
   //       .orderBy('created', 'desc')
   //       .limit(10)
   //       .onSnapshot(snapshot => {
   //          const _arrayComment = snapshot.docs.map(doc => doc.data())
   //          this.setState({
   //             arrayComment: _arrayComment
   //          })
   //       })
   // }
   // onCommentTextChange(e) {
   //    const text = e.target.value
   //    this.setState({ comment: text })
   // }
   // sendComment() {
   //    // Validate
   //    const comment = this.state.comment.trim()
   //    if (comment.length < 10) {
   //       alert("Minimum 10 characters"); return
   //    }

   //    // Disable button
   //    this.setState({ buttonCommentDisabled: true })
   //    // Update single doc in collection like
   //    const refCommentAdd = firebaseFirestore.collection('comment').doc()
   //    const batch = firebaseFirestore.batch()
   //    batch.set(refCommentAdd, {
   //       created: firebaseTimestamp,
   //       comment: comment,
   //       userId: localStorage.getItem('uid'),
   //       contentId: this.state.contentId,
   //       userFullName: this.state.userFullName
   //    })
   //    batch.update(this.refContent, { commentCount: firebaseIncrement })
   //    batch.commit()
   //       .then(() =>
   //          this.setState({
   //             comment: '',
   //             buttonCommentDisabled: false
   //          })) // success
   // }
   onPlayVideo() {
      // No-op
   }
   onLoadedDataVideo() {
      // Update collection view-history
      const refViewHistoryAdd = firebaseFirestore.collection('view-history').doc()
      const batch = firebaseFirestore.batch()
      batch.set(refViewHistoryAdd, {
         created: firebaseTimestamp,
         userId: localStorage.getItem('uid'),
         contentId: this.props.match.params.contentId,
      })
      batch.update(this.refContent, { viewCount: firebaseIncrement })
      batch.commit()
   }
   componentWillUnmount() {
      if (this.listenerContent) { this.listenerContent() }
      // if (this.listenerComment) { this.listenerComment() }
   }
   render() {
      return (<div>{this.state.dataContent ?
         <DetailsView
            data={this.state.dataContent}
            isLiked={this.state.isLiked}
            buttonLikeDisabled={this.state.buttonLikeDisabled}
            buttonCommentDisabled={this.state.buttonCommentDisabled}
            changeLike={this.changeLike}
            commentTextValue={this.state.comment}
            onCommentTextChange={this.onCommentTextChange}
            sendComment={this.sendComment}
            arrayComment={this.state.arrayComment}
            onPlayVideo={this.onPlayVideo}
            onLoadedDataVideo={this.onLoadedDataVideo} />
         : <DetailsSkeleton />}</div>)
   }
}

function DetailsView(props) {
   const isLiked = props.isLiked
   const styleLiked = {
      color: 'white',
      backgroundColor: '#58c3c1',
      borderColor: '#58c3c1',
      boxShadow: 'none'
   }
   const styleNotLiked = {
      color: '#58c3c1',
      backgroundColor: 'white',
      borderColor: '#58c3c1'
   }
   const styleTextFieldComment = {
      borderColor: '#58c3c1',
   }
   const styleButtonSend = {
      marginLeft: 16,
      color: 'white',
      backgroundColor: '#58c3c1',
      borderColor: '#58c3c1',
   }
   const clickLike = (e) => {
      // e.preventDefault()
      props.changeLike(!isLiked) // Perform Like / Dislike
   }
   const videoId = '576092645'
   return (
      <div className="align-items-center justify-content-center" >

         <div className="d-flex justify-content-center mb-3">
            <Widget className="p-4" style={{ width: '100%', maxWidth: 960 }}>
               <div className="headline-2">{props.data.title}</div>
               <p className="headline-4">{props.data.creatorName}</p>
            </Widget>
         </div>

         <div className="d-flex justify-content-center">

            {/* <ReactPlayer url={"https://vimeo.com/" + videoId}
               controls={false}
               onPlay={props.onPlayVideo}
               onReady={props.onLoadedDataVideo}
            /> */}

            {/* Use Vimeo Pro to get direct link and use <video> https://help.vimeo.com/hc/en-us/articles/224823567-Third-party-players-links */}
            <video
               onPlay={props.onPlayVideo}
               onLoadedData={props.onLoadedDataVideo}
               className="w-100"
               style={{ maxWidth: 960 }}
               disablePictureInPicture controlsList="nodownload"
               // autoPlay
               controls preload="auto" onContextMenu={e => e.preventDefault()}
               poster={props.data.posterUrl} >
               <source src={props.data.videoUrl} />
            </video>
         </div>

         <div className="d-flex justify-content-center mt-4">
            <Row className="d-flex justify-content-center align-items-start w-100" style={{ maxWidth: 960 }}>
               <Col className="pl-grid-col col d-flex flex-column" xs={6} lg={6}>
                  <Widget className="d-flex flex-column justify-content-center p-3 align-items-center">
                     <Row className="d-flex align-items-center justify-content-center" >
                        <AiTwotoneFire size={18} />&nbsp;<p>{props.data.likeCount}</p><span style={{ width: 16 }} />
                        <Button
                           disabled={props.buttonLikeDisabled}
                           variant={isLiked === true ? 'contained' : 'outlined'}
                           style={isLiked === true ? styleLiked : styleNotLiked}
                           className="align-self-center" size="medium"
                           onClick={clickLike}
                           startIcon={<AiTwotoneFire />}>
                           {isLiked === true ? 'UNLIKE' : 'LIKE'}
                        </Button>
                     </Row>
                  </Widget>
               </Col>

               <Col className="pl-grid-col col d-flex flex-column " xs={3} lg={3}>
                  <Widget className="d-flex justify-content-center align-items-center p-2" >
                     <MdComment size={18} />&nbsp;&nbsp;<p>{props.data.commentCount}</p>
                  </Widget>
               </Col>

               <Col className="pl-grid-col col d-flex flex-column " xs={3} lg={3}>
                  <Widget className="d-flex justify-content-center align-items-center p-2" >
                     <FaEye size={18} />&nbsp;&nbsp;<p>{props.data.viewCount}</p>
                  </Widget>
               </Col>

            </Row>
         </div>

         {/* <div className="d-flex justify-content-center mt-4">
            <Widget className="flex-column p-3 mt-1" style={{ marginBottom: 16, width: '100%', maxWidth: 960 }}>
               <div className="d-flex flex-row">
                  <TextField
                     id="outlined-basic" label="Comment" className="flex-grow-1"
                     variant="outlined" multiline rows={2}
                     value={props.commentTextValue}
                     onChange={props.onCommentTextChange} />
                  <Button
                     disabled={props.buttonCommentDisabled}
                     style={styleButtonSend}
                     className="align-self-center" size="medium" variant='contained'
                     onClick={props.sendComment}>
                     SEND
                  </Button>
               </div>

               <div className="mt-4 headline-3">RECENT COMMENTS</div>

               {props.arrayComment.map(doc => {

                  let difference = null
                  if (doc.created) {
                     const previous = doc.created.seconds * 1000
                     const current = (new Date()).getTime()
                     difference = timeDifference(current, previous)
                  }

                  return (
                     <div className='mt-3' key={doc.id}>
                        <p>{doc.comment}</p>
                        <small className='text-muted'>{doc.userFullName}{difference ? ", " + difference : ""}</small>
                     </div>)
               })}

            </Widget>
         </div> */}

      </div>
   )
}

function DetailsSkeleton() {
   return (
      <Row>
         <Col className="pr-grid-col mt-2 mb-4">
            <Widget className="p-3">
               <Skeleton />
               <Skeleton height={220} />
               <Skeleton className='mt-2' />
               <Skeleton />
               <Skeleton height={300} />
            </Widget>
         </Col>
      </Row>
   )
}
