import React, { Component, useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore, firebaseTimestamp } from '../../actions/firebase'
import { SegmentedControl } from 'segmented-control'
import { Button, TextField } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import { teal } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import { IoSend } from 'react-icons/io5'
import { Alert } from "reactstrap"
import successIcon from "../../assets/notificationsIcons/successIcon.svg"
import s from "../../components/Notification/Notification.module.scss"

export const IOSSwitch = withStyles((theme) => ({
   root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
   },
   switchBase: {
      padding: 1,
      '&$checked': {
         transform: 'translateX(16px)',
         color: theme.palette.common.white,
         '& + $track': {
            backgroundColor: '#58c3c1',
            opacity: 1,
            border: 'none',
         },
      },
      '&$focusVisible $thumb': {
         color: '#58c3c1',
         border: '6px solid #fff',
      },
   },
   thumb: {
      width: 24,
      height: 24,
   },
   track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
   },
   checked: {},
   focusVisible: {},
}))(({ classes, ...props }) => {
   return (
      <Switch
         focusVisibleClassName={classes.focusVisible}
         disableRipple
         classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
         }}
         {...props} />
   )
})