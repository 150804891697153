import React, { Component, useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore, firebaseTimestamp } from '../../actions/firebase'
import { SegmentedControl } from 'segmented-control'
import { Button, TextField } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import { teal } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import { IoSend } from 'react-icons/io5'
import { Alert } from "reactstrap"
import successIcon from "../../assets/notificationsIcons/successIcon.svg"
import s from "../../components/Notification/Notification.module.scss"
import { deviceDetect } from "react-device-detect"
import { IOSSwitch } from '../../components/VicCustom/IOSSwitch.js'

export default function Feedback() {
   const classes = useStyles();
   const [mood, setMood] = useState('4-very-happy')
   const [feedbackText, setFeedbackText] = useState('')
   const [canContactBack, setCanContactBack] = useState(false)
   const [isSendingFeedback, setSendingFeedback] = useState(false)
   const [isFeedbackSent, setFeedbackSent] = useState(false)
   const { userAuth } = useContext(UserContext)

   const buttonClassname = clsx({
      [classes.buttonOriginal]: isFeedbackSent == false,
      [classes.buttonSuccess]: isFeedbackSent == true,
   })

   useEffect(() => {
      // Run only once, like in componentDidMount()
   }, [])


   const feedbackTextChange = (e) => {
      const text = e.target.value
      setFeedbackText(text)
   }
   const sendFeedback = (e) => {
      if (isSendingFeedback === true) { return } // Ensure no double sending
      // Prepare
      setFeedbackSent(false)
      setSendingFeedback(true)
      // Sending
      firebaseFirestore.collection('feedback')
         .add({
            created: firebaseTimestamp,
            message: feedbackText,
            mood: mood,
            // senderEmail: userAuth.email,
            // senderId: userAuth.uid,
            deviceDetect: deviceDetect(),
            canContactBack: canContactBack
         })
         .then(() => {
            // Success
            setFeedbackSent(true)
            setSendingFeedback(false)
         })
         .catch(error => {
            // Fail
            setFeedbackSent(false)
            setSendingFeedback(false)
            alert(error)
         })
   }

   return (
      <div style={{ justifyContent: 'center', display: 'flex' }}>
         <div style={{ maxWidth: 600 }}>
            <div className='headline-1'>FEEDBACK</div>
            <div className='mt-4'>I'm feeling...</div>
            <SegmentedControl
               name="segmentedMood"
               options={[
                  { label: "😡", value: "1-very-unhappy", disabled: (isSendingFeedback || isFeedbackSent) },
                  { label: "🙁", value: "2-slightly-unhappy", disabled: (isSendingFeedback || isFeedbackSent) },
                  { label: "🙂", value: "3-slightly-happy", disabled: (isSendingFeedback || isFeedbackSent) },
                  { label: "🥰", value: "4-very-happy", disabled: (isSendingFeedback || isFeedbackSent), default: true }
               ]}
               setValue={newValue => setMood(newValue)}
               style={{
                  fontSize: 44, marginTop: 8, maxWidth: 600, height: 136,
                  color: '#58c3c166', backgroundColor: 'white'
               }} />

            <TextField
               label="Please tell us what you think"
               className="d-flex" variant="outlined" id="outlined-basic"
               multiline rows={4}
               disabled={isSendingFeedback || isFeedbackSent}
               style={{ maxWidth: 600, backgroundColor: 'white' }}
               value={feedbackText}
               onChange={feedbackTextChange} />

            <FormControlLabel
               control={
                  <IOSSwitch
                     name="iosSwitch"
                     checked={canContactBack}
                     onChange={e => setCanContactBack(e.target.checked)} />
               }
               disabled={isSendingFeedback || isFeedbackSent}
               labelPlacement="start" className="mt-4"
               label="Please contact me back" />

            <div className={classes.buttonParent} >
               <Button
                  variant='contained' size="large"
                  // style={styleButtonSend}
                  className={buttonClassname}
                  color="primary"
                  endIcon={<IoSend />}
                  disabled={isSendingFeedback || isFeedbackSent}
                  onClick={sendFeedback}>SEND FEEDBACK&nbsp;</Button>
               {isSendingFeedback && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>

            <Alert
               className={s.notificationContainer}
               style={{ backgroundColor: '#58c3c1', maxWidth: 600 }}
               isOpen={isFeedbackSent}>
               <div className={s.notificationIconContainer}><img src={successIcon} alt="..." /></div>
               <div className={s.messageContainer}>Thank you for your feedback!</div>
            </Alert>
         </div>
      </div >
   )
}

const useStyles = makeStyles((theme) => ({
   buttonParent: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
      justifyContent: 'center',
      position: 'relative',
      maxWidth: 600
   },
   buttonOriginal: {
      backgroundColor: teal[200],
      '&:hover': {
         backgroundColor: teal[200],
      },
   },
   buttonSuccess: {
      backgroundColor: teal[200],
      '&:hover': {
         backgroundColor: teal[200],
      },
   },
   buttonProgress: {
      color: teal[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
}))