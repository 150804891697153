import React, { useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore, firebaseTimestamp } from '../../actions/firebase'
import { Button, TextField } from '@material-ui/core'
import clsx from 'clsx'
import { teal } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import Widget from "../../components/Widget/Widget.js"
import { Row, Col } from 'reactstrap'
import 'react-calendar/dist/Calendar.css'
import { makeStyles } from '@material-ui/core/styles'
import { ImageList, ImageListItem, ImageListItemBar, ListSubheader, IconButton } from '@material-ui/core'
import { MdCloudUpload } from 'react-icons/md'

export default function VideoUpload() {
   const classes = useStyles()
   const { userAuth } = useContext(UserContext)
   const [savedFile, setSavedFile] = useState(null)
   const [savedFileUrl, setSavedFileUrl] = useState(null)
   const [videoTitle, setVideoTitle] = useState(null)
   const [videoDescription, setVideoDescription] = useState(null)

   const buttonClassname = clsx({
      [classes.buttonOriginal]: true,
   })

   useEffect(() => {
      // Run only once, like in componentDidMount()
      const uid = localStorage.getItem('uid')
      if (!uid) { return }

   }, [])

   const clickInput = (e) => {
      e.preventDefault()

      const file = Array.from(e.target.files)[0]
      setSavedFile(file)
   }

   // After setSavedFile finish
   useEffect(() => {
      if (!savedFile) { return }
      const url = URL.createObjectURL(savedFile)
      setSavedFileUrl(url)

      console.log(url)
   }, [savedFile])

   return (
      <div className='justify-content-center d-flex'>

         <div style={{ maxWidth: 600, width: '100%' }}>
            <div className='headline-1'>Video Content Upload</div>

            <Row>
               <Col className="pr-grid-col mt-4">
                  <Widget className="p-3 muted" style={{ fontSize: 14 }}>
                     <p>NOTES</p>
                     <p>Maximum video length is 20 minutes</p>
                     <p>Video must follow our content guidelines</p>
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mt-3">
                  <Widget className="p-3">
                     <div className="mb-2 muted">Select video file</div>
                     <input type='file' accept='video/*' onChange={clickInput} />

                     {savedFileUrl ?
                        (<div>
                           <div className="mb-1 mt-4 muted">Video preview</div>
                           <video controls preload="auto" className='w-100' controlsList="nodownload"
                              onContextMenu={e => e.preventDefault()} disablePictureInPicture={true}
                              src={savedFileUrl} />
                        </div>) : <div />}
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mt-3">
                  <Widget className="p-3">
                     <TextField
                        value={videoTitle}
                        onChange={e => setVideoTitle(e.target.value)}
                        id="outlined-search" label="Video Title"
                        variant="outlined" className="w-100 mb-3" />

                     <TextField
                        value={videoDescription}
                        onChange={e => setVideoDescription(e.target.value)}
                        id="outlined-search" label="Video Description"
                        multiline rows={4}
                        variant="outlined" className="w-100" />
                  </Widget>
               </Col>
            </Row>

            <div className={classes.buttonParent} >
               <Button
                  variant='contained' size="large"
                  className={buttonClassname}
                  color="primary"
                  endIcon={<MdCloudUpload />}
                  onClick={() => { }}>Upload Video&nbsp;</Button>
            </div>

         </div>
      </div >
   )
}

const useStyles = makeStyles((theme) => ({
   buttonParent: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 32,
      marginBottom: 16,
      justifyContent: 'center',
      position: 'relative',
      maxWidth: 600
   },
   buttonOriginal: {
      backgroundColor: red[300],
      '&:hover': {
         backgroundColor: red[300],
      },
   },
   buttonSuccess: {
      backgroundColor: teal[200],
      '&:hover': {
         backgroundColor: teal[200],
      },
   },
   buttonProgress: {
      color: teal[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
   },
   imageList: {
      width: '100%',
      height: '100%',
   },
   icon: {
      color: 'rgba(255, 255, 255, 0.54)',
   },
}))