import React, { useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore, firebaseTimestamp, firebaseFunctions } from '../../actions/firebase'
import Widget from "../../components/Widget/Widget.js"
import { Row, Col } from 'reactstrap'
import 'react-calendar/dist/Calendar.css'
import { useRouteMatch } from 'react-router';
import ReactDOM from "react-dom"
import { SegmentedControl } from 'segmented-control'
import VicPayButton from '../../components/VicCustom/VicPayButton.js'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


export default function SubscribeTo() {
   const { userAuth } = useContext(UserContext)
   const [creatorId, setCreatorId] = useState(null)
   const routeMatch = useRouteMatch() // get url param, alternatively can use useLocation hooks

   useEffect(() => {
      // Run only once, like in componentDidMount()
      const uid = localStorage.getItem('uid')
      if (!uid) { return }

      // Save creatorId from url param
      const creatorId = routeMatch.params.creatorId
      setCreatorId(creatorId)

      // Good to go, retrieve one time all creators
      // const refCreatorPublic = firebaseFirestore.collection('creator-public')
      // refCreatorPublic.get().then(snapshot => {
      //    const _arrayContent = snapshot.docs.map(_doc => _doc.data())
      //    setArrayCreatorAll(_arrayContent)
      // })
   }, [])

   const initialOptions = {
      "client-id": "AZFNzLLJQOF3mRlkJJ6vTxJ3q_ME-6_rnKpYF1h2LNSmQugIv9GWaDhxNOiNmw69Oiw5bjINqcYTxt2L",
      currency: 'USD', // default currency is USD
      intent: "capture",
      // "data-client-token": "abc123xyz==",
   }

   return (
      <div className='justify-content-center d-flex'>

         <div style={{ maxWidth: 600, width: '100%' }}>
            <div className='headline-1'>Subscribe to Mr Victor Chandra PhD</div>

            <Row>
               <Col className="pr-grid-col mt-4">
                  <Widget className="d-flex flex-row p-2">

                     <img src='/dummy-image-list/burgers.jpg' className='w-50' />
                     <div className='ml-3 pt-2'>
                        <div>Mr Victor Chandra PhD</div>
                        <div className='muted'>Status: Not Subscribed</div>
                     </div>

                  </Widget>
               </Col>
            </Row>

            {/* <Row>
               <Col className="pr-grid-col mt-4">
                  <Widget>
                     <div className='d-flex p-3'>
                        <div className='muted' style={{ width: 140 }}>Current Status</div>
                        <div>Not subscribed</div>
                     </div>
                  </Widget>
               </Col>
            </Row> */}

            <Row>
               <Col className='mt-4'>
                  <Widget className='p-3'>
                     <div className='d-flex'>
                        <div className='muted' style={{ width: 66 }}>Start</div>
                        <div>10 August 2021</div>
                     </div>
                     <div className='d-flex'>
                        <div className='muted' style={{ width: 66 }}>End</div>
                        <div>09 September 2021</div>
                     </div>
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className='mt-4'>
                  <Widget className='p-3'>

                     <PayPalScriptProvider options={initialOptions}>
                        <VicPayButton />
                     </PayPalScriptProvider>

                  </Widget>
               </Col>
            </Row>

         </div>
      </div>
   )
}
