import React, { Component, useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore, firebaseTimestamp, firebaseStorage } from '../../actions/firebase'
import { Button, FormControl, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import { teal } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import { Alert } from "reactstrap"
import successIcon from "../../assets/notificationsIcons/successIcon.svg"
import s from "../../pages/tables/Tables.module.scss"
import Widget from "../../components/Widget/Widget.js"
import { deviceDetect } from "react-device-detect"
import { Row, Col } from 'reactstrap'
import userImg from "../../assets/Victor2020.jpg"
import { GiPiggyBank } from 'react-icons/gi'
import { MdLocationCity } from 'react-icons/md'
import { BiEditAlt } from 'react-icons/bi'
import { IoLocationOutline } from 'react-icons/io5'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {
   CountryDropdown,
   RegionDropdown,
   // CountryRegionData
} from 'react-country-region-selector'
import { SegmentedControl } from 'segmented-control'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { IoSend } from 'react-icons/io5'
import { AiOutlineRead } from 'react-icons/ai'
import { GiPublicSpeaker } from 'react-icons/gi'
import { useHistory } from 'react-router'
import { InputAdornment } from '@material-ui/core'
import { IOSSwitch } from '../../components/VicCustom/IOSSwitch.js'

export default function CreatorProfile() {
   const classes = useStyles()
   const history = useHistory()
   const { userAuth } = useContext(UserContext)
   const [isModalOpen, setModalOpen] = useState(false)
   const [dataCreatorPublic, setDataCreatorPublic] = useState(null)
   const [dataCreatorPrivate, setDataCreatorPrivate] = useState(null)
   // Edit data
   const [editNameDisplay, setEditNameDisplay] = useState(null)
   const [editDescription, setEditDescription] = useState(null)
   const [isUploadingProfile, setUploadingProfile] = useState(false)
   // Cover Image
   const [savedFile, setSavedFile] = useState(null)
   const [savedFileUrl, setSavedFileUrl] = useState(null)
   // Currency
   const [amountUSD, setAmountUSD] = useState(null)
   const [amountAUD, setAmountAUD] = useState(null)
   const [amountCAD, setAmountCAD] = useState(null)
   const [amountGBP, setAmountGBP] = useState(null)


   const buttonEditProfile = clsx({
      [classes.buttonOriginal]: true,
   })
   const buttonSeePublicProfile = clsx({
      [classes.buttonSuccess]: true,
   })


   useEffect(() => {
      // Run only once, like in componentDidMount()
      const uid = localStorage.getItem('uid')
      if (!uid) { return }
      // Good to go, listener creator public and private
      const refCreatorPublic = firebaseFirestore.collection('creator-public').doc(uid)
      const listenerCreatorPublic = refCreatorPublic.onSnapshot(doc => {
         const data = doc.data() // reuse
         // View data
         setDataCreatorPublic(data)
         // Edit data
         setEditNameDisplay(data.creatorName)
         setEditDescription(data.description)
         // Edit pricing
         if (data.pricing) {
            setAmountUSD(data.pricing.USD)
            setAmountAUD(data.pricing.AUD)
            setAmountCAD(data.pricing.CAD)
            setAmountGBP(data.pricing.GBP)
         }
      })
      // Listener private
      const refCreatorPrivate = firebaseFirestore.collection('creator-private').doc(uid)
      const listenerCreatorPrivate = refCreatorPrivate.onSnapshot(doc => {
         setDataCreatorPrivate(doc.data()) // no edit data
      })

      // Returned function will be called on component unmount 
      return () => {
         listenerCreatorPublic()
         listenerCreatorPrivate()
      }
   }, [])

   // After setSavedFile finish
   useEffect(() => {
      if (!savedFile) { return }
      const url = URL.createObjectURL(savedFile)
      setSavedFileUrl(url)

      console.log(url)
   }, [savedFile])

   const openModal = (e) => {
      if (isModalOpen === true) { return } // Ensure no double sending
      setModalOpen(true)
   }

   const closeModal = (e) => {
      setModalOpen(false)
   }

   const uploadProfile = () => {
      // Validate
      if (isUploadingProfile === true) { return } // Ensure no double sending
      if (editNameDisplay === '' || editDescription === '' || !editNameDisplay || !editDescription) {
         alert("Please fill in your name and description"); return
      }
      if (amountUSD === 0 || amountAUD === 0 || amountCAD === 0 || amountGBP === 0) {
         alert("Amount cannot be 0"); return
      }
      if (amountUSD === null && amountAUD === null && amountCAD === null && amountGBP === null) {
         alert("Must set at least one currency"); return
      }
      setUploadingProfile(true)
      const uid = localStorage.getItem('uid')
      if (!uid) { return }
      // Pricing
      const pricing = {
         USD: amountUSD,
         AUD: amountAUD,
         CAD: amountCAD,
         GBP: amountGBP
      }
      // Good to go, listener user
      const refCreatorPublic = firebaseFirestore.collection('creator-public').doc(uid)
      refCreatorPublic.update({
         creatorName: editNameDisplay.trim(),
         description: editDescription.trim(),
         pricing: pricing
      }).then(() => {
         // Success
         setUploadingProfile(false)
         setModalOpen(false)
      }).catch(error => {
         // Fail
         setUploadingProfile(false)
         alert(error)
      })
   }

   const getBecomeCreatorSince = (date) => {
      const moment = require('moment')
      const _moment = moment(date.toDate())
      return _moment.format('DD MMMM YYYY')
   }

   const clickInput = async (e) => {
      e.preventDefault()
      const uid = localStorage.getItem('uid')
      if (!uid) { return }

      // Save locally
      const file = Array.from(e.target.files)[0]
      setSavedFile(file)

      // Save on Firestore
      const fileRefOriginal = firebaseStorage.child("upload_image/" + "cover_" + uid)
      const snapshot = await fileRefOriginal.put(file)
      const fileRefCompressed = firebaseStorage.child("upload_image/" + "cover_" + uid + "_1024x1024")
      const url = await fileRefCompressed.getDownloadURL()

      const refCreatorPublic = firebaseFirestore.collection('creator-public').doc(uid)
      refCreatorPublic.update({
         coverImageUrl: url,
      }).then(() => {
         // Success
      }).catch(error => {
         // Fail
         alert(error)
      })
   }

   const openMyPublicProfile = (e) => {
      e.preventDefault()
      const uid = localStorage.getItem('uid')
      if (!uid) { return }
      history.push('/inside/creator-details/' + uid)
   }

   return (
      <div className='justify-content-center d-flex'>

         {/* Modal */}
         <Modal
            className={classes.modal}
            open={isModalOpen}
            onClose={closeModal}
            closeAfterTransition
            style={{ overflow: 'scroll' }}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}>
            <Fade in={isModalOpen}>
               <div className={classes.modalContent}>
                  <h3 className="mb-3">Edit Creator Profile</h3>
                  <TextField
                     label="Creator Name"
                     value={editNameDisplay}
                     // helperText="Display name will be shown on your comments and posts"
                     onChange={e => setEditNameDisplay(e.target.value)}
                     className="w-100 mt-3" variant="outlined" id="outlined-basic" placeholder="" />
                  <TextField
                     label="Description"
                     value={editDescription}
                     multiline rows={3}
                     onChange={e => setEditDescription(e.target.value)}
                     className="w-100 mt-3" variant="outlined" id="outlined-basic" placeholder="" />


                  <p className='muted mb-1 mt-3'>Set Pricing</p>

                  <CurrencyEditCell
                     enabled={amountUSD !== null}
                     setEnabled={enabled => enabled === true ? setAmountUSD(0) : setAmountUSD(null)}
                     onTextChange={value => setAmountUSD(value)}
                     value={amountUSD}
                     flag='🇺🇸'
                     currencyName='United States Dollar'
                     currencyCode='USD'
                     currencySymbol='$' />

                  <CurrencyEditCell
                     enabled={amountAUD !== null}
                     setEnabled={enabled => enabled === true ? setAmountAUD(0) : setAmountAUD(null)}
                     onTextChange={value => setAmountAUD(value)}
                     value={amountAUD}
                     flag='🇦🇺'
                     currencyName='Australian Dollar'
                     currencyCode='AUD'
                     currencySymbol='$' />

                  <CurrencyEditCell
                     enabled={amountCAD !== null}
                     setEnabled={enabled => enabled === true ? setAmountCAD(0) : setAmountCAD(null)}
                     onTextChange={value => setAmountCAD(value)}
                     value={amountCAD}
                     flag='🇨🇦'
                     currencyName='Canadian Dollar'
                     currencyCode='CAD'
                     currencySymbol='$' />

                  <CurrencyEditCell
                     enabled={amountGBP !== null}
                     setEnabled={enabled => enabled === true ? setAmountGBP(0) : setAmountGBP(null)}
                     onTextChange={value => setAmountGBP(value)}
                     value={amountGBP}
                     flag='🇬🇧'
                     currencyName='Pound Sterling'
                     currencyCode='GBP'
                     currencySymbol='£' />

                  <div className={classes.buttonParent} >
                     <Button
                        variant='contained' size="large"
                        // style={styleButtonSend}
                        className={buttonEditProfile}
                        color="primary"
                        endIcon={<IoSend />}
                        disabled={isUploadingProfile}
                        onClick={uploadProfile}>Confirm Update Profile&nbsp;</Button>
                     {isUploadingProfile && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>

               </div>
            </Fade>
         </Modal>

         <div style={{ maxWidth: 600, width: '100%' }}>
            <div className='headline-1'>My Creator Profile</div>

            <Row>
               <Col className="pr-grid-col mt-4 mb-4">
                  <Widget className="d-flex pt-3 pl-3 pr-3">
                     <div className="col-3">
                        <img src={dataCreatorPublic ? dataCreatorPublic.photoUrl : ''} className="w-100 rounded-circle" />
                     </div>

                     <div className="col-9">
                        <div className={s.content}>
                           <div className="body-2">{dataCreatorPublic ? dataCreatorPublic.creatorName : ''}</div>
                           <div className="body-3 muted d-md-block">display name</div>
                        </div>
                        <div className={s.content}>
                           <div className="body-2">{dataCreatorPublic ? dataCreatorPublic.creatorTier : ''}</div>
                           <div className="body-3 muted d-md-block">creator tier</div>
                        </div>
                        <div className={s.content}>
                           <div className="body-2">{dataCreatorPublic ? getBecomeCreatorSince(dataCreatorPublic.created) : ''}</div>
                           <div className="body-3 muted d-md-block">creator since</div>
                        </div>
                     </div>
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mb-4">
                  <Widget className="pt-3 pb-3 pl-3 pr-3">
                     <p className='muted'>Cover Image</p>

                     {dataCreatorPublic && dataCreatorPublic.coverImageUrl ?
                        (<div>
                           <img src={dataCreatorPublic.coverImageUrl} className="w-100 mt-2" />
                           <label for='change-image' className='muted'>Update cover image:</label>
                           <input id='change-image' type='file' accept='image/*' onChange={clickInput} className='m-2' />
                        </div>) :
                        (<div className='d-flex row justify-content-center align-items-center' style={{ minHeight: 180 }}>
                           <div>
                              <p style={{ color: '#c3585a', fontSize: 15 }}>You have no Cover Image. Click this button to set Cover Image.</p>
                              <input type='file' accept='image/*' onChange={clickInput} />
                           </div>
                        </div>)}

                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mb-4">
                  <Widget className="p-3">
                     <div className="mb-2 muted">Intro video</div>
                     <div>
                        <video controls preload="auto" className='w-100' controlsList="nodownload"
                           onContextMenu={e => e.preventDefault()} disablePictureInPicture={true}
                           poster='https://firebasestorage.googleapis.com/v0/b/playwithfire-7e45b.appspot.com/o/scriber_video%2Fgriff_200x200.jpg?alt=media&token=0271de12-0d34-4469-b512-e42f61e9ec9e'
                           src='https://firebasestorage.googleapis.com/v0/b/playwithfire-7e45b.appspot.com/o/scriber_video%2Fintro%20video.mp4?alt=media&token=ce283d12-41c3-4008-aa1b-03c27ccc25a4' />
                        <label for='change-image' className='muted'>Update intro video:</label>
                        <input id='change-image' type='file' accept='image/*' onChange={clickInput} className='m-2' />
                     </div>
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mb-4">
                  <Widget className="pt-4 pl-3 pr-3">

                     <div className={s.content}>
                        <div className="body-2">
                           <AiOutlineRead className='mr-3' size={18} />{dataCreatorPublic ? dataCreatorPublic.description : ''}
                        </div>
                        <div className="body-3 muted d-md-block">description</div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">
                           Total content: {dataCreatorPublic ? dataCreatorPublic.contentCount : ''}
                        </div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">
                           Total subscribers: {dataCreatorPublic ? dataCreatorPublic.subscriberCount : ''}
                        </div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">
                           Total content view: {dataCreatorPublic ? dataCreatorPublic.inViewCount : ''}
                        </div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">
                           Total incoming likes: {dataCreatorPublic ? dataCreatorPublic.inLikeCount : ''}
                        </div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">
                           Total incoming comments: {dataCreatorPublic ? dataCreatorPublic.inCommentCount : ''}
                        </div>
                     </div>

                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mb-4">
                  <Widget className="p-3">
                     <p className='muted mb-2'>My Pricing</p>

                     {dataCreatorPublic && dataCreatorPublic.pricing ? <div>

                        {dataCreatorPublic.pricing.USD ? <CurrencyViewCell
                           flag='🇺🇸'
                           amount={dataCreatorPublic.pricing.USD}
                           currencyName='United States Dollar'
                           currencyCode='USD'
                           currencySymbol='$' /> : <div />}

                        {dataCreatorPublic.pricing.AUD ? <CurrencyViewCell
                           flag='🇦🇺'
                           amount={dataCreatorPublic.pricing.AUD}
                           currencyName='Australian Dollar'
                           currencyCode='AUD'
                           currencySymbol='$' /> : <div />}

                        {dataCreatorPublic.pricing.CAD ? <CurrencyViewCell
                           flag='🇨🇦'
                           amount={dataCreatorPublic.pricing.CAD}
                           currencyName='Canadian Dollar'
                           currencyCode='CAD'
                           currencySymbol='$' /> : <div />}

                        {dataCreatorPublic.pricing.GBP ? <CurrencyViewCell
                           flag='🇬🇧'
                           amount={dataCreatorPublic.pricing.GBP}
                           currencyName='Pound Sterling'
                           currencyCode='GBP'
                           currencySymbol='£' /> : <div />}

                     </div> : <div>No pricing data. Please add pricing.</div>}

                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mb-4">
                  <Widget className="pt-4 pl-3 pr-3">
                     <div className={s.content}>
                        <div className="body-2">
                           <GiPiggyBank className='mr-3' size={18} />{dataCreatorPrivate ? 'AUD ' + dataCreatorPrivate.currentBalance : ''}
                        </div>
                        <div className="body-3 muted d-md-block">current balance</div>
                     </div>
                  </Widget>
               </Col>
            </Row>

            <div className={classes.buttonParent} >
               <Button
                  variant='contained' size="large"
                  className={buttonSeePublicProfile}
                  color="primary"
                  endIcon={<GiPublicSpeaker />}
                  disabled={isModalOpen}
                  onClick={openMyPublicProfile}>See How My Profile Look to User&nbsp;</Button>
            </div>

            <div className={classes.buttonParent} >
               <Button
                  variant='contained' size="large"
                  className={buttonEditProfile}
                  color="primary"
                  endIcon={<BiEditAlt />}
                  disabled={isModalOpen}
                  onClick={openModal}>Edit Creator Profile and Pricing &nbsp;</Button>
            </div>

         </div>
      </div >
   )
}

const useStyles = makeStyles((theme) => ({
   buttonParent: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 32,
      marginBottom: 16,
      justifyContent: 'center',
      position: 'relative',
      maxWidth: 600
   },
   buttonOriginal: {
      backgroundColor: red[300],
      '&:hover': {
         backgroundColor: red[300],
      },
   },
   buttonSuccess: {
      backgroundColor: teal[200],
      '&:hover': {
         backgroundColor: teal[200],
      },
   },
   buttonProgress: {
      color: teal[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   modalContent: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: 400
   },
}))

function CurrencyEditCell(props) {
   return (
      <div className={s.content}>
         <div>
            <span>
               <IOSSwitch
                  name="iosSwitch"
                  checked={props.enabled}
                  onChange={e => props.setEnabled(e.target.checked)} />
            </span>
            <span className='ml-2 mr-4' style={{ opacity: props.enabled === true ? 1.0 : 0.4 }}>
               {props.flag} &nbsp;{props.currencyName}
            </span>
         </div>
         <TextField
            id="standard-number"
            label={props.currencyCode}
            type="number"
            disabled={!props.enabled}
            style={{ width: 100, opacity: props.enabled === true ? 1.0 : 0.4 }}
            onChange={e => props.onTextChange(e.target.valueAsNumber)}
            value={props.value}
            InputProps={{
               startAdornment: <InputAdornment position="start">{props.currencySymbol}</InputAdornment>,
            }}
            InputLabelProps={{
               shrink: true,
            }} />
      </div>
   )
}

function CurrencyViewCell(props) {
   return (
      <div className={s.content}>
         <div>
            <span className='ml-2 mr-4'>
               {props.flag}&nbsp;&nbsp;{props.currencyName}
            </span>
         </div>
         <div>{props.currencySymbol}{props.amount.toFixed(2)} per month</div>
      </div>
   )
}