import React, { Component, useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore, firebaseTimestamp } from '../../actions/firebase'
import { Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import { teal } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import s from "../../pages/tables/Tables.module.scss"
import Widget from "../../components/Widget/Widget.js"
import { Row, Col } from 'reactstrap'
import { FaRegEnvelopeOpen } from 'react-icons/fa'
import { MdLocationCity } from 'react-icons/md'
import { BiEditAlt } from 'react-icons/bi'
import { IoLocationOutline } from 'react-icons/io5'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {
   CountryDropdown,
   RegionDropdown,
   // CountryRegionData
} from 'react-country-region-selector'
import { SegmentedControl } from 'segmented-control'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { IoSend } from 'react-icons/io5'

export default function UserProfile() {
   const classes = useStyles()
   const { userAuth } = useContext(UserContext)
   const [isModalOpen, setModalOpen] = useState(false)
   const [dataUser, setDataUser] = useState(null)
   // Edit data
   const [editNameDisplay, setEditNameDisplay] = useState(null)
   const [editNameFirst, setEditNameFirst] = useState(null)
   const [editNameLast, setEditNameLast] = useState(null)
   const [editCountry, setEditCountry] = useState(null)
   const [editState, setEditState] = useState(null)
   const [editCity, setEditCity] = useState(null)
   const [editGender, setEditGender] = useState('other')
   const [editDateOfBirth, setEditDateOfBirth] = useState(null) // string DD/MM/YYYY
   const [dateDob, setDateDob] = useState(new Date()) // date object for calendar
   const [isUploadingProfile, setUploadingProfile] = useState(false)

   const buttonClassname = clsx({
      [classes.buttonOriginal]: true,
   })

   useEffect(() => {
      // Run only once, like in componentDidMount()
      const uid = localStorage.getItem('uid')
      if (!uid) { return }
      // Good to go, listener user
      const refUserProfile = firebaseFirestore.collection('user').doc(uid)
      const listenerUser = refUserProfile.onSnapshot(doc => {
         // View data
         setDataUser(doc.data())
         // Edit data
         setEditNameDisplay(doc.data().nameDisplay)
         setEditNameFirst(doc.data().nameFirst)
         setEditNameLast(doc.data().nameLast)
         setEditCountry(doc.data().country)
         setEditState(doc.data().state)
         setEditCity(doc.data().city)
         setEditGender(doc.data().gender)
         setEditDateOfBirth(doc.data().dateOfBirth)
      })

      // Returned function will be called on component unmount 
      return () => {
         listenerUser()
      }
   }, [])

   useEffect(() => {
      // Update calendar with date object
      setDateDob(getCalendarDate(editDateOfBirth))
   }, [editDateOfBirth])

   const openModal = (e) => {
      if (isModalOpen === true) { return } // Ensure no double sending
      setModalOpen(true)
   }

   const closeModal = (e) => {
      setModalOpen(false)
   }

   // Helper
   const getCalendarDate = (date) => {
      if (!date) {
         return new Date()
      }
      // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
      // January - 0, February - 1, etc.
      var parts = date.split('/')
      return new Date(parts[2], parts[1] - 1, parts[0])
   }
   // Helper
   const getCalendarString = (date) => {
      const moment = require('moment')
      const _moment = moment(date)
      return _moment.format('DD/MM/YYYY')
   }

   // User update calendar
   const updateDateOfBirth = (date) => {
      const string = getCalendarString(date)
      setEditDateOfBirth(string)
      const _date = getCalendarDate(string)
      setDateDob(_date)
   }

   const uploadProfile = () => {
      // Validate
      if (isUploadingProfile === true) { return } // Ensure no double sending
      if (editNameDisplay === '' || editNameFirst === '' || editNameLast === '' || !editNameDisplay || !editNameFirst || !editNameLast) {
         alert("Please fill in your name"); return
      }
      // Check age
      const moment = require('moment')
      const years = moment().diff(dateDob, 'years');
      if (years < 17) {
         alert("You must be minimum 17 years old"); return
      }

      setUploadingProfile(true)
      const uid = localStorage.getItem('uid')
      if (!uid) { return }
      // Good to go, listener user
      const refUserProfile = firebaseFirestore.collection('user').doc(uid)
      refUserProfile.update({
         nameDisplay: editNameDisplay.trim(),
         nameFirst: editNameFirst.trim(),
         nameLast: editNameLast.trim(),
         country: editCountry,
         state: editState,
         city: editCity.trim(),
         gender: editGender,
         dateOfBirth: editDateOfBirth
      })
         .then(() => {
            // Success
            setUploadingProfile(false)
            setModalOpen(false)
         })
         .catch(error => {
            // Fail
            setUploadingProfile(false)
            alert(error)
         })
   }

   const becomeCreator = async () => {
      // alert("Mechanism to be discussed")
      const uid = localStorage.getItem('uid')
      if (!uid) { return }

      // Step 1: Update user flag
      const refUserProfile = firebaseFirestore.collection('user').doc(uid)
      await refUserProfile.update({ isCreator: true })

      // Step 2: Create NEW creator. No previous creator
      const refCreatorProfile = firebaseFirestore.collection('creator-public').doc(uid)
      const doc = await refCreatorProfile.get()
      if (doc.exists === true) { alert("Object public already exists"); return }
      // Good to go, create
      refCreatorProfile.set({
         created: firebaseTimestamp,
         email: dataUser.email,
         userId: uid,
         creatorName: dataUser.nameDisplay,
         description: "!!! please edit this, it will be seen by your subscribers !!!",
         creatorTier: "tier-one",
         photoUrl: dataUser.photoUrl,
         subscriberCount: 0,
         inViewCount: 0,
         inLikeCount: 0,
         inCommentCount: 0,
         contentCount: 0,
         contentTags: []

      }).then(async () => {
         // Step 2 creator-private
         const refCreatorPrivate = firebaseFirestore.collection('creator-private').doc(uid)
         const doc = await refCreatorPrivate.get()
         if (doc.exists === true) { alert("Object private already exists"); return }
         return refCreatorPrivate.set({
            created: firebaseTimestamp,
            email: dataUser.email,
            userId: uid,
            paypalEmail: "",
            currentBalance: 0
         })
      })
         .then(() => alert("Success"))
         .catch(error => alert(error))
   }

   return (
      <div style={{ justifyContent: 'center', display: 'flex' }}>

         {/* Modal */}
         <Modal
            className={classes.modal}
            open={isModalOpen}
            onClose={closeModal}
            closeAfterTransition
            style={{ overflow: 'scroll' }}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}>
            <Fade in={isModalOpen}>
               <div className={classes.modalContent}>
                  <h3 className="mb-2">Edit User Profile</h3>
                  <TextField
                     label="Display Name"
                     value={editNameDisplay}
                     // helperText="Display name will be shown on your comments and posts"
                     onChange={e => setEditNameDisplay(e.target.value)}
                     className="w-100 mt-3" variant="outlined" id="outlined-basic" placeholder="" />
                  <TextField
                     label="First Name"
                     value={editNameFirst}
                     onChange={e => setEditNameFirst(e.target.value)}
                     className="w-100 mt-3" variant="outlined" id="outlined-basic" placeholder="" />
                  <TextField
                     label="Last Name"
                     value={editNameLast}
                     onChange={e => setEditNameLast(e.target.value)}
                     className="w-100 mt-3" variant="outlined" id="outlined-basic" placeholder="" />

                  <span className="d-flex mt-4 w-100 align-items-center">
                     <p className="w-25">Country</p>
                     <CountryDropdown
                        className="w-75"
                        value={editCountry}
                        onChange={val => {
                           setEditCountry(val)
                           setEditState(null)
                        }} />
                  </span>

                  <span className="d-flex mt-3 w-100 align-items-center">
                     <p className="w-25">State</p>
                     <RegionDropdown
                        className="w-75"
                        country={editCountry}
                        value={editState}
                        onChange={val => setEditState(val)} />
                  </span>

                  <TextField
                     label="City"
                     value={editCity}
                     onChange={e => setEditCity(e.target.value)}
                     className="w-100 mt-3" variant="outlined" id="outlined-basic" placeholder="" />

                  <span className="d-flex mt-3 w-100 align-items-center">
                     <p className="w-25">Gender</p>
                     <SegmentedControl
                        name="segmentedMood"
                        options={[
                           { label: "Male", value: "male", default: (editGender === "male") },
                           { label: "Female", value: "female", default: (editGender === "female") },
                           { label: "Other", value: "other", default: (editGender === "other") },
                        ]}
                        setValue={value => setEditGender(value.toLowerCase())}
                        className="w-75"
                        style={{ height: 50, color: '#777' }} />
                  </span>

                  <p className="mt-3 mb-2">Date of Birth&nbsp;<strong>{editDateOfBirth}</strong></p>
                  <Calendar
                     onChange={updateDateOfBirth}
                     value={dateDob} />

                  <div className={classes.buttonContainerConfirmEditProfile} >
                     <Button
                        variant='contained' size="large"
                        // style={styleButtonSend}
                        className={buttonClassname}
                        color="primary"
                        endIcon={<IoSend />}
                        disabled={isUploadingProfile}
                        onClick={uploadProfile}>Confirm Update Profile&nbsp;</Button>
                     {isUploadingProfile && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>

               </div>
            </Fade>
         </Modal>

         {/* Main Content */}
         <div style={{ maxWidth: 600, width: '100%' }}>
            <div className='headline-1'>My User Profile</div>

            <Row>
               <Col className="pr-grid-col mt-4 mb-4">
                  <Widget className="d-flex pt-3 pl-3 pr-3">
                     <div className="col-2">
                        <img src={dataUser ? dataUser.photoUrl : null} className="w-100 rounded-circle" />
                     </div>

                     <div className="col-10">
                        <div className={s.content}>
                           <div className="body-2">{dataUser ? dataUser.nameDisplay : ''}</div>
                           <div className="body-3 muted d-md-block">display name</div>
                        </div>
                        <div className={s.content}>
                           <div className="body-2">{dataUser ? dataUser.nameFirst : ''}</div>
                           <div className="body-3 muted d-md-block">first name</div>
                        </div>
                        <div className={s.content}>
                           <div className="body-2">{dataUser ? dataUser.nameLast : ''}</div>
                           <div className="body-3 muted d-md-block">last name</div>
                        </div>
                     </div>
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mb-4">
                  <Widget className="pt-4 pl-3 pr-3">

                     <div className={s.content}>
                        <div className="body-2">
                           <FaRegEnvelopeOpen className='mr-3' size={18} />{dataUser ? dataUser.email : ''}
                        </div>
                        <div className="body-3 muted d-md-block">email</div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">
                           <MdLocationCity className='mr-3' size={18} />{dataUser ? dataUser.city : ''}
                        </div>
                        <div className="body-3 muted d-md-block">city</div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">
                           <IoLocationOutline className='mr-3' size={18} />{dataUser ? dataUser.state : ''}
                        </div>
                        <div className="body-3 muted d-md-block">state</div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">
                           <IoLocationOutline className='mr-3' size={18} />{dataUser ? dataUser.country : ''}
                        </div>
                        <div className="body-3 muted d-md-block">country</div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">{dataUser ? dataUser.gender.toUpperCase() : ''}</div>
                        <div className="body-3 muted d-md-block">gender</div>
                     </div>

                     <div className={s.content}>
                        <div className="body-2">{dataUser ? dataUser.dateOfBirth : ''}</div>
                        <div className="body-3 muted d-md-block">date of birth</div>
                     </div>

                  </Widget>
               </Col>
            </Row>

            {/* Edit User Profile */}
            <div className={classes.buttonContainerEditProfile} >
               <Button
                  variant='contained' size="large"
                  className={buttonClassname}
                  color="primary"
                  endIcon={<BiEditAlt />}
                  disabled={isModalOpen}
                  onClick={openModal}>Edit User Profile&nbsp;</Button>
            </div>

            {/* Become a Creator */}
            {dataUser && dataUser.isCreator === false ?
               (<Row>
                  <Col className="pr-grid-col mb-4 mt-3">
                     <Widget className="pt-4 pl-3 pr-3">
                        <div className={s.content}>
                           <div className="w-50">
                              You are not a creator. Become a creator by clicking this button.
                           </div>
                           <Button
                              variant='contained' size="medium"
                              className={classes.buttonBecomeCreator}
                              color="primary"
                              // endIcon={<BiEditAlt />}
                              onClick={becomeCreator}>Become a Creator&nbsp;</Button>
                        </div>
                     </Widget>
                  </Col>
               </Row>) : <div />}

         </div>
      </div >
   )
}

const useStyles = makeStyles((theme) => ({
   buttonContainerEditProfile: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 8,
      marginBottom: 16,
      justifyContent: 'center',
      position: 'relative',
      maxWidth: 600
   },
   buttonContainerConfirmEditProfile: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 32,
      marginBottom: 16,
      justifyContent: 'center',
      position: 'relative',
      maxWidth: 600
   },
   buttonOriginal: {
      backgroundColor: red[300],
      '&:hover': {
         backgroundColor: red[300],
      },
   },
   buttonBecomeCreator: {
      width: 200,
      backgroundColor: teal[200],
      '&:hover': {
         backgroundColor: teal[200],
      },
   },
   buttonProgress: {
      color: teal[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   modalContent: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minWidth: 400
   },
}))