import React, {
   useState,
   useEffect
} from "react"
import {
   useHistory,
} from "react-router-dom"
import {
   Container,
   Row,
   Col,
   FormGroup,
   Input,
} from "reactstrap"
import { Button, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { IoSend } from 'react-icons/io5'
import { teal } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import Widget from "../../components/Widget/Widget.js"
import Footer from "../../components/Footer/Footer.js"
import {
   firebaseFirestore,
   firebaseAnalytics,
   firebaseTimestamp,
   firebaseIncrement,
} from "../../actions/firebase"
import { deviceDetect } from "react-device-detect"
import YoutubeVideo from "./YoutubeVideo.js"


export default function LeaveALegacy() {
   const [name, setName] = useState('')
   const [email, setEmail] = useState('')
   const [location, setLocation] = useState({})
   const [isSending, setSending] = useState(false)
   const [tallyNumber, setTallyNumber] = useState(0)
   const history = useHistory()
   const classes = useStyles()

   useEffect(() => {
      // Concurrent #1: 
      // const statsRef = firebaseFirestore.collection('stats').doc('stats')
      // statsRef.get().then(doc => {
      //    const totalUser = doc.data().totalUser
      //    setTallyNumber(totalUser)
      // })

      // Concurrent #2: IPAPI
      fetch('https://ipapi.co/json/')
         .then(response => {
            if (response.ok) {
               return response.json()
            }
            throw response
         })
         .then(data => setLocation(data))
         .catch(e => alert(e))
   }, [])

   const buttonClassname = clsx({
      [classes.buttonSuccess]: true
   })

   const doSubmit = (e) => {
      e.preventDefault()
      firebaseAnalytics.logEvent('press_submit')
      // Validate
      if (name === '' || email === '') {
         alert("Please fill in your name and email"); return
      }
      if (isEmail(email) == false) {
         alert("Please fill in a proper email"); return
      }
      if (isSending === true) { return } // Ensure no double sending
      setSending(true)

      // Let's go!
      const _email = email.trim()
      var userRef = firebaseFirestore.collection('beta-user-international').doc(_email)
      userRef.get().then(doc => {
         // Ignore if already registered
         if (doc.exists) {
            alert(_email + ' has already been registered.')
            setSending(false)
            return
         }
         // Good to add
         // const statsRef = firebaseFirestore.collection('stats').doc('stats')
         const batch = firebaseFirestore.batch()
         batch.set(userRef, {
            created: firebaseTimestamp,
            name: name.trim(),
            email: _email,
            location: location,
            country: location.country_name,
            region: location.region,
            city: location.city,
            deviceDetect: deviceDetect()
         })
         // batch.update(statsRef, { totalUser: firebaseIncrement })
         batch.commit().then(() =>
            history.push('/inside/join-success') // move page
         ).catch((error) =>
            console.error("Error writing document: ", error)
         )
      }).catch((error) => {
         console.log("Error getting document:", error)
      })
   }

   const changeName = e => {
      setName(e.target.value.trim())
   }

   const changeEmail = e => {
      setEmail(e.target.value.trim())
   }

   /*
   Horizontal with loop background mp4 mov
   https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/explanation-video%2Fleave-a-legacy-hor.mp4?alt=media&token=0c1c77f6-9f16-4b64-aa0f-8e942bdb1cce
   https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/explanation-video%2Fleave-a-legacy-hor.mov?alt=media&token=5a48a154-af02-4d28-b3ab-871b3f41182c

   Original box
   https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/explanation-video%2Foriginal.mp4?alt=media&token=3070ddbd-d3f5-4d9b-9faa-9e4cdd6b84ee
   */
   return (
      <div>

         <YoutubeVideo />

         <Container className="col-12 d-flex justify-content-center">
            <Row className="d-flex align-items-center">
               <Col>
                  {/* <Widget className="widget-auth widget-p-none w-100"> */}
                  {/* <video
                        poster="https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/explanation-video%2Fposter.jpg?alt=media&token=625b4579-c654-4eee-9905-045abb56c03b"
                        id="vid" width="100%" preload="auto" onContextMenu={e => e.preventDefault()}
                        controls disablePictureInPicture controlsList="nodownload">
                        <source
                           src="https://firebasestorage.googleapis.com/v0/b/leave-a-legacy-join.appspot.com/o/explanation-video%2Fleave-a-legacy-hor.mp4?alt=media&token=0c1c77f6-9f16-4b64-aa0f-8e942bdb1cce"
                           type="video/mp4" />
                     </video> */}
                  {/* </Widget> */}
                  {/* <Tally tallyNumber={tallyNumber}/> */}

                  <Widget className="widget-auth widget-p-lg mt-4">
                     <div className="d-flex align-items-center justify-content-center py-3">
                        <p className="auth-header mb-0">Leave a Legacy!</p>
                     </div>
                     <div className="auth-info my-2">
                        <p className="mb-4">We are currently developing an app in secrecy 😉 We believe you will love the app we are creating which in turn will reward you a monetary value for using it. Yes, you read right, using an app that actually puts food on the table and will do much more than just pay the bills!</p>
                        <p className="mb-4">Sounds to good to be true right? Not this app! This app is made for people out there who want to empower others to be better, to strive for more in life.</p>
                        <p>Sound interesting? Intrigued? You should be. This is something new and fresh and we believe it should be here, we make this for you and the greater good. Change your mind, change your life.</p>
                     </div>
                     <div className="row justify-content-center">
                        <form onSubmit={doSubmit} className="auth-form">
                           <FormGroup className="my-3">
                              <Input
                                 className="input-transparent pl-3"
                                 id="name" name="name" placeholder="Name"
                                 value={name} onChange={changeName} />
                           </FormGroup>
                           <FormGroup className="my-3">
                              <Input
                                 className="input-transparent pl-3"
                                 id="email" type="email" name="email" placeholder="Email"
                                 value={email}
                                 onChange={changeEmail} />
                           </FormGroup>
                           <div className={classes.buttonParent} >
                              <Button
                                 variant='contained' size="large"
                                 // style={styleButtonSend}
                                 className={buttonClassname}
                                 color="primary"
                                 endIcon={<IoSend />}
                                 disabled={isSending}
                                 onClick={doSubmit}>Count me in!</Button>
                              {isSending && <CircularProgress size={24} className={classes.buttonProgress} />}
                           </div>
                        </form>
                     </div>
                  </Widget>

               </Col>
            </Row>

         </Container>
      </div>
   )
}

const useStyles = makeStyles((theme) => ({
   buttonParent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      marginTop: 16,
      maxWidth: 600
   },
   buttonSuccess: {
      backgroundColor: teal[200],
      '&:hover': {
         backgroundColor: teal[200],
      },
   },
   buttonProgress: {
      color: teal[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   root: {
      width: 300,
   },
   margin: {
      height: theme.spacing(3),
   },
}))

function isEmail(email) {
   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
}
