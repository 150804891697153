import React, { useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore, firebaseTimestamp } from '../../actions/firebase'
import { Button, TextField } from '@material-ui/core'
import clsx from 'clsx'
import { teal } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import Widget from "../../components/Widget/Widget.js"
import { Row, Col } from 'reactstrap'
import 'react-calendar/dist/Calendar.css'
import { makeStyles } from '@material-ui/core/styles'
import { ImageList, ImageListItem, ImageListItemBar, ListSubheader, IconButton } from '@material-ui/core'

export default function Subscriber() {
   const { userAuth } = useContext(UserContext)
   const [isModalOpen, setModalOpen] = useState(false)
   const [arrayCreatorAll, setArrayCreatorAll] = useState([])
   const [arrayCreatorFiltered, setArrayCreatorFiltered] = useState([])
   const [searchQuery, setSearchQuery] = useState('')

   const itemData = [
      {
         name: 'Mr Victor Chandra PhD',
         status: 'active',
         paid: '40',
         started: '10 July 2021',
         ended: '09 August 2021',
      },
      {
         name: 'Mr Victor Chandra PhD',
         status: 'active',
         paid: '40',
         started: '10 July 2021',
         ended: '09 August 2021',
      },
      {
         name: 'Mr Victor Chandra PhD',
         status: 'active',
         paid: '40',
         started: '10 July 2021',
         ended: '09 August 2021',
      },
      {
         name: 'Mr Victor Chandra PhD',
         status: 'active',
         paid: '40',
         started: '10 July 2021',
         ended: '09 August 2021',
      },
      {
         name: 'Mr Victor Chandra PhD',
         status: 'active',
         paid: '40',
         started: '10 July 2021',
         ended: '09 August 2021',
      },
   ]

   useEffect(() => {
      // Run only once, like in componentDidMount()
      const uid = localStorage.getItem('uid')
      if (!uid) { return }
      // Good to go, retrieve one time all creators
      // const refCreatorPublic = firebaseFirestore.collection('creator-public')
      // refCreatorPublic.get().then(snapshot => {
      //    const _arrayContent = snapshot.docs.map(_doc => _doc.data())
      //    setArrayCreatorAll(_arrayContent)
      // })
   }, [])

   return (
      <div className='justify-content-center d-flex'>

         <div style={{ maxWidth: 600, width: '100%' }}>
            <div className='headline-1 mb-3'>Subscriber List</div>

            {itemData.map(item => (
               <Row>
                  <Col className='m-2'>
                     <Widget className='p-3 pl-4 pr-4'>
                        <div className=''>{item.name}</div>
                        <div className='d-flex flex-row' style={{ color: '#aaa', fontSize: 14 }}>
                        <div className='w-50'>Status {item.status}</div>
                           <div className='w-50'>${item.paid}</div>
                        </div>
                        <div className='d-flex flex-row' style={{ color: '#aaa', fontSize: 14 }}>
                           <div className='w-50'>Started {item.started}</div>
                           <div className='w-50'>Ended {item.ended}</div>
                        </div>
                     </Widget>
                  </Col>
               </Row>
            ))}


         </div>
      </div >
   )
}
