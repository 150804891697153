import React, { useState, useEffect, useContext } from 'react'
import { SegmentedControl } from 'segmented-control'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { firebaseFirestore, firebaseTimestamp, firebaseFunctions } from '../../actions/firebase'
import { usePayPalScriptReducer } from "@paypal/react-paypal-js";

/*
user-one@personal.example.com
userone123
*/

const paypalCreateOrder = firebaseFunctions.httpsCallable("paypalCreateOrder")
const paypalHandleOrder = firebaseFunctions.httpsCallable("paypalHandleOrder")

export default function VicPayButton() {
   // get the state for the sdk script and the dispatch method
   const [{ options }, dispatch] = usePayPalScriptReducer()
   const [currency, setCurrency] = useState(options.currency) // default value taken from existing options

   function onCurrencyChange(value) {
      setCurrency(value);
      dispatch({
         type: "resetOptions",
         value: {
            ...options,
            currency: value,
         },
      })
   }

   return (
      <div>
         <div className='muted'>Supported Currencies {currency}</div>

         <SegmentedControl
            name="segmentedCurrency"
            options={[
               { label: "USD", value: "USD", disabled: false, default: (currency === 'USD') },
               { label: "AUD", value: "AUD", disabled: false, default: (currency === 'AUD') },
               { label: "GBP", value: "GBP", disabled: false, default: (currency === 'GBP'), disabled: true },
               { label: "CAD", value: "CAD", disabled: false, default: (currency === 'CAD') }
            ]}
            className='w-75 mt-2'
            setValue={onCurrencyChange}
            style={{ fontSize: 18, height: 54, color: '#58c3c1', backgroundColor: 'white' }} />
         <div className='headline-2 mb-3'>{currency} $50 per month</div>

         <PayPalButtons
            createOrder={(data, actions) => {
               const param = {
                  currency: currency
               }
               return paypalCreateOrder(param)
                  .then(response => {
                     if (response.data.status === "success") {
                        return response.data.id
                     } else {
                        alert("Error. Please try again");
                        return null
                     }
                  }).catch(error => alert(error))
            }}
            onApprove={(data, actions) => { // data complete, actions {"order":{},"payment":null,"subscription":{}}
               paypalHandleOrder(data)
                  .then(response => {
                     alert("Success!")
                  }).catch(error => alert(error))
            }} />

      </div>

   )
}
