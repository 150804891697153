import React, { useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore, firebaseTimestamp } from '../../actions/firebase'
import { Button, TextField } from '@material-ui/core'
import clsx from 'clsx'
import { teal } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import Widget from "../../components/Widget/Widget.js"
import { Row, Col } from 'reactstrap'
import 'react-calendar/dist/Calendar.css'
import { makeStyles } from '@material-ui/core/styles'
import { ImageList, ImageListItem, ImageListItemBar, ListSubheader, IconButton } from '@material-ui/core'
import { MdCloudUpload } from 'react-icons/md'
import { useHistory, useRouteMatch } from 'react-router'

export default function CreatorDetails() {
   const history = useHistory()
   const classes = useStyles()
   const { userAuth } = useContext(UserContext)
   const [savedFile, setSavedFile] = useState(null)
   const [savedFileUrl, setSavedFileUrl] = useState(null)
   const [videoTitle, setVideoTitle] = useState(null)
   const [videoDescription, setVideoDescription] = useState(null)
   const routeMatch = useRouteMatch() // get url param, alternatively can use useLocation hooks
   const [creator, setCreator] = useState(null)

   const buttonClassname = clsx({
      [classes.buttonOriginal]: true,
   })

   useEffect(() => {
      // Run only once, like in componentDidMount()
      // const uid = localStorage.getItem('uid')
      // if (!uid) { return }


      const refCreator = firebaseFirestore.collection('creator').doc(routeMatch.params.creatorId)
      refCreator.get().then(doc => setCreator(doc.data()))
   }, [])

   const clickSubscribeTo = (e) => {
      e.preventDefault()
      const creatorId = routeMatch.params.creatorId
      history.push('/inside/subscribe-to/' + creatorId)
   }

   // After setSavedFile finish
   useEffect(() => {
      if (!savedFile) { return }
      const url = URL.createObjectURL(savedFile)
      setSavedFileUrl(url)

      console.log(url)
   }, [savedFile])

   return (
      <div className='justify-content-center d-flex'>

         <div style={{ maxWidth: 600, width: '100%' }}>
            <div className='headline-1'>Creator Details{creator ? ": " + creator.name : ""}</div>

            <Row>
               <Col className="pr-grid-col mt-4">
                  <Widget className="d-flex flex-row p-2 justify-content-center">
                     <img src={creator ? creator.coverUrl : ""} className='w-100' />
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mt-3">
                  <Widget>
                     <div className='p-3'>
                        <div className='mb-2'>{creator ? creator.name : ""}</div>
                        <div className='muted'>{creator ? creator.description : ""}</div>
                     </div>
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mt-3">
                  <Widget className="p-3">
                     <div className="mb-2 muted">Intro video</div>
                     <video controls preload="auto" className='w-100' controlsList="nodownload"
                        onContextMenu={e => e.preventDefault()} disablePictureInPicture={true}
                        poster={creator ? creator.posterUrl : ""}
                        src={creator ? creator.videoUrl : ""} />
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mt-3">
                  <Widget className="p-3">

                     <div className='d-flex'>
                        <div className='muted' style={{ width: 66 }}>72</div>
                        <div>Videos</div>
                     </div>

                     <div className='d-flex'>
                        <div className='muted' style={{ width: 66 }}>240</div>
                        <div>Subscribers</div>
                     </div>

                     <div className='d-flex'>
                        <div className='muted' style={{ width: 66 }}>542</div>
                        <div>Likes</div>
                     </div>

                     <div className='d-flex'>
                        <div className='muted' style={{ width: 66 }}>154</div>
                        <div>Comments</div>
                     </div>

                     <div className='d-flex'>
                        <div className='muted' style={{ width: 66 }}>9,853</div>
                        <div>Views</div>
                     </div>

                  </Widget>
               </Col>
            </Row>

            {/* <div className={classes.buttonParent} >
               <Button
                  variant='contained' size="large"
                  className={buttonClassname}
                  color="primary"
                  onClick={clickSubscribeTo}>Make Mr Victor Chandra PhD my Mentor</Button>
            </div> */}

         </div>
      </div >
   )
}

const useStyles = makeStyles((theme) => ({
   buttonParent: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 32,
      marginBottom: 16,
      justifyContent: 'center',
      position: 'relative',
      maxWidth: 600
   },
   buttonOriginal: {
      textTransform: 'none',
      backgroundColor: teal[300],
      '&:hover': {
         backgroundColor: teal[300],
      },
   },
   buttonSuccess: {
      backgroundColor: teal[200],
      '&:hover': {
         backgroundColor: teal[200],
      },
   },
   buttonProgress: {
      color: teal[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
   },
   imageList: {
      width: '100%',
      height: '100%',
   },
   icon: {
      color: 'rgba(255, 255, 255, 0.54)',
   },
}))