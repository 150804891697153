import React, { useState, useEffect, useContext } from 'react'
import UserProvider, { UserContext } from "../../actions/UserProvider.js"
import { firebaseFirestore } from '../../actions/firebase'
import { Button, TextField } from '@material-ui/core'
import clsx from 'clsx'
import { teal } from '@material-ui/core/colors'
import { red } from '@material-ui/core/colors'
import Widget from "../../components/Widget/Widget.js"
import { Row, Col } from 'reactstrap'
import 'react-calendar/dist/Calendar.css'
import { makeStyles } from '@material-ui/core/styles'
import { ImageList, ImageListItem, ImageListItemBar, ListSubheader, IconButton } from '@material-ui/core'
import { useHistory } from 'react-router'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'


export default function FindCreators() {
   const history = useHistory()
   const classes = useStyles()
   const { userAuth } = useContext(UserContext)
   const [arrayCreatorAll, setArrayCreatorAll] = useState([])
   const [arrayCreatorFiltered, setArrayCreatorFiltered] = useState([])
   const [searchQuery, setSearchQuery] = useState('')
   const [arrayCreatorFeatured, setArrayCreatorFeatured] = useState(null)

   const buttonClassname = clsx({
      [classes.buttonOriginal]: true,
   })

   const itemData = [
      {
         img: '/dummy-image-list/breakfast.jpg',
         title: 'Breakfast',
         author: 'jill111',
      },
      {
         img: '/dummy-image-list/burgers.jpg',
         title: 'Tasty burger',
         author: 'director90',
      },
      {
         img: '/dummy-image-list/camera.jpg',
         title: 'Camera',
         author: 'Danson67',
      },
      {
         img: '/dummy-image-list/morning.jpg',
         title: 'Morning',
         author: 'fancycrave1',
         // cols: 2,
         // featured: true,
      },
      {
         img: '/dummy-image-list/hats.jpg',
         title: 'Hats',
         author: 'Hans',
      },
      {
         img: '/dummy-image-list/honey.jpg',
         title: 'Honey',
         author: 'fancycravel',
      },
      {
         img: '/dummy-image-list/vegetables.jpg',
         title: 'Vegetables',
         author: 'jill111',
      },
      {
         img: '/dummy-image-list/plant.jpg',
         title: 'Water plant',
         author: 'BkrmadtyaKarki',
      },
      {
         img: '/dummy-image-list/mushroom.jpg',
         title: 'Mushrooms',
         author: 'PublicDomainPictures',
      },
      {
         img: '/dummy-image-list/olive.jpg',
         title: 'Olive oil',
         author: 'congerdesign',
      },
      {
         img: '/dummy-image-list/star.jpg',
         title: 'Sea star',
         author: '821292',
      },
      {
         img: '/dummy-image-list/bike.jpg',
         title: 'Bike',
         author: 'danfador',
      },
   ]

   useEffect(() => {
      // Good to go, retrieve one time all creators
      const refCreatorPublic = firebaseFirestore.collection('creator')
      refCreatorPublic.get().then(snapshot => {
         const _arrayContent = snapshot.docs
         setArrayCreatorAll(_arrayContent)
      })
   }, [])

   useEffect(() => {
      // Filter
      if (searchQuery.trim() === '') {
         setArrayCreatorFiltered([]); return
      }
      const query = searchQuery.toLowerCase()
      const filtered = arrayCreatorAll.filter(obj => obj.data().name.toLowerCase().search(query) != -1)
      setArrayCreatorFiltered(filtered)
   }, [searchQuery])

   return (
      <div className='justify-content-center d-flex'>

         <div style={{ maxWidth: 600, width: '100%' }}>
            <div className='headline-1'>Find Creators</div>

            <Row>
               <Col className="pr-grid-col mt-4">
                  <Widget className="d-flex p-2">
                     <TextField
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        id="outlined-search" label="Search creator name"
                        type="search" variant="outlined" className="w-100" />
                  </Widget>
               </Col>
            </Row>

            <Row style={{ display: arrayCreatorFiltered.length == 0 ? 'none' : '' }}>
               <Col className="pr-grid-col mt-4 mb-4">
                  <div className="ml-2">Search results</div>
                  <Widget className="pt-4 pl-3 pr-3">
                     <div className={classes.root}>
                        <ImageList rowHeight={170} className={classes.imageList} cols={2}>
                           {arrayCreatorFiltered.map(item => (
                              <ImageListItem key={item.id} onClick={() => {
                                 history.push(`/inside/creator-details/${item.id}`)
                              }}>
                                 <img src={item.data().coverUrl} />
                                 <ImageListItemBar
                                    style={{ opacity: 0.6, height: '25%' }}
                                    title={item.data().name} />
                              </ImageListItem>
                           ))}
                        </ImageList>
                     </div>
                  </Widget>
               </Col>
            </Row>

            <Row>
               <Col className="pr-grid-col mt-4 mb-4">
                  <div className="ml-2">Featured Creators</div>
                  <Widget className="p-3">
                     <div>
                        {arrayCreatorAll ? arrayCreatorAll.map(content => <FeaturedCell content={content} key={content.id} />) : <FeaturedSkeletonGroup />}
                     </div>
                  </Widget>
               </Col>
            </Row>

            {/* <Row>
               <Col className="pr-grid-col mt-4 mb-4">
                  <div className="ml-2">Categories</div>
                  <Widget className="pt-4 pl-3 pr-3">
                     <div className={classes.root}>
                        <ImageList rowHeight={170} gap={10} className={classes.imageList} cols={2}>
                           {itemData.map(item => (
                              <ImageListItem key={item.img} onClick={() => {
                                 const creatorId = 111
                                 history.push('/inside/creator-details/' + creatorId)
                              }}>
                                 <img src={item.img} alt={item.title} />
                                 <ImageListItemBar
                                    style={{ opacity: 0.6, height: '25%' }}
                                    title={item.title} />
                              </ImageListItem>
                           ))}
                        </ImageList>
                     </div>
                  </Widget>
               </Col>
            </Row> */}

         </div>
      </div >
   )
}

const useStyles = makeStyles((theme) => ({
   buttonParent: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 32,
      marginBottom: 16,
      justifyContent: 'center',
      position: 'relative',
      maxWidth: 600
   },
   buttonOriginal: {
      backgroundColor: red[300],
      '&:hover': {
         backgroundColor: red[300],
      },
   },
   buttonSuccess: {
      backgroundColor: teal[200],
      '&:hover': {
         backgroundColor: teal[200],
      },
   },
   buttonProgress: {
      color: teal[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
   },
   imageList: {
      width: '100%',
      height: '100%',
   },
   icon: {
      color: 'rgba(255, 255, 255, 0.54)',
   },
}))

function FeaturedCell(props) {
   return (
      <Link to={`/inside/creator-details/${props.content.id}`} key={props.content.id}>
         <Row>
            <Col className="pr-grid-col mt-2">
               <Widget className="mb-3">
                  <img className="w-100 img-fluid rounded" src={props.content.data().coverUrl} />
                  <div className="row container-fluid mt-2">
                     <div className="col">
                        <p className="headline-2">{props.content.data().name}</p>
                     </div>
                  </div>
               </Widget>
            </Col>
         </Row>
      </Link>
   )
}

function FeaturedSkeletonGroup() {
   var rows = [];
   for (var i = 0; i < 3; i++) {
      rows.push(FeaturedSkeletonCell(i));
   }
   return (<div>{rows}</div>);
}

function FeaturedSkeletonCell(index) {
   return (
      <Row key={index}>
         <Col className="pr-grid-col">
            <Widget className="p-3">
               <Skeleton />
               <Skeleton className='w-100' height={180} />
               <Skeleton className='mt-2' />
            </Widget>
         </Col>
      </Row>
   )
}